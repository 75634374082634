import useObjectSelector from '@nord/ui/src/hooks/useObjectSelector'
import { selectCurrentPortfolio } from '@nord/ui/src/store/current/portfolioId'
import { valueToDate } from '@nord/ui/src/utilities/formatDate'
import { differenceInBusinessDays } from 'date-fns'
import camelCase from 'lodash/camelCase'
import { useMemo } from 'react'

import useIsPayoutAccount from '../../../hooks/useIsPayoutAccount'

const defaultAppStates = ['readyToOnboard', 'createdInSaxo', 'depositReceived']

const usePortfolioAppState = () => {
  const isPayoutAccount = useIsPayoutAccount()
  // @ts-expect-error TS(2339) FIXME: Property 'appState' does not exist on type 'unknow... Remove this comment to see the full error message
  const { appState, investedAt } = useObjectSelector(selectCurrentPortfolio)

  const formattedAppState = camelCase(appState)

  const isOldInvested = differenceInBusinessDays(new Date(), valueToDate(investedAt)) > 3

  const data = useMemo(() => {
    if (isPayoutAccount) return 'payoutAccount'
    if (defaultAppStates.includes(formattedAppState)) return formattedAppState
    if (isOldInvested) return 'oldInvested'

    return 'newInvested'
  }, [formattedAppState, isOldInvested, isPayoutAccount])

  return data
}

export default usePortfolioAppState
