import useApi from '@nord/ui/src/hooks/useApi'
import { selectCurrentPortfolioId } from '@nord/ui/src/store/current/portfolioId'
import { useSelector } from 'react-redux'

const useUpdateSustainabilityPreference = () => {
  const portfolioId = useSelector(selectCurrentPortfolioId)

  const updateSustainabilityPreferences = useApi(
    `/portfolios/${portfolioId}/sustainability_preferences`,
    {
      method: 'PATCH',
      withCredentials: true,
      errorHandling: {
        ignore: {
          client: true,
        },
      },
    },
  )

  return updateSustainabilityPreferences
}

export default useUpdateSustainabilityPreference
