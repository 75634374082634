import ForgotPasswordForm from '@nord/ui/src/components/ForgotPasswordForm'
import React from 'react'

import FormPageWrapper from '../FormPageWrapper'

const ForgotPasswordPage = () => (
  <FormPageWrapper>
    <ForgotPasswordForm />
  </FormPageWrapper>
)

export default ForgotPasswordPage
