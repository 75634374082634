import BaffleButton from '@nord/ui/src/components/BaffleButton'
import classNames from 'classnames'
import React from 'react'

import styles from './DateButton.module.scss'

export interface DateButtonProps {
  active?: boolean
  children?: React.ReactNode
  className?: string
  onClick?(): void
}

const DateButton = ({ children, active, className, ...props }: DateButtonProps) => (
  <BaffleButton
    variant="link"
    size="sm"
    transform="uppercase"
    className={classNames(
      styles.dateButton,
      'mr-lg-3',
      'font-size-md',
      'flex-grow-1',
      'flex-lg-grow-0',
      { 'text-primary': active, 'text-deep-dark': !active },
      className,
    )}
    {...props}
  >
    {children}
  </BaffleButton>
)

DateButton.defaultProps = {
  active: false,
  children: undefined,
  className: undefined,
  onClick: undefined,
}

export default DateButton
