import anime from 'animejs'
import { useCallback, useRef, useEffect } from 'react'

import useAnimation from '../useAnimation'

const animationOptions = {
  animeFunction: anime.timeline,
}

const useTimelineAnimation = (animation: any, timelineOptions = {}) => {
  // @ts-expect-error TS(2339) FIXME: Property 'show' does not exist on type '{}'.
  const { show, stagger } = timelineOptions
  const indices = useRef([])

  const [loadTimeline, timeline] = useAnimation(animation, show, animationOptions)

  useEffect(() => {
    if (!timeline) {
      // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      loadTimeline()
    }
  }, [loadTimeline, timeline])

  const add = useCallback(
    (targets: any, itemAnimeOptions: any, options = {}) => {
      // @ts-expect-error TS(2339) FIXME: Property 'offset' does not exist on type '{}'.
      const { offset, index } = options
      const currentIndices = indices.current

      let currentIndex
      // @ts-expect-error TS(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
      if (currentIndices.includes(targets)) {
        // @ts-expect-error TS(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
        currentIndex = currentIndices.indexOf(targets)
      } else if (index) {
        currentIndex = index
        // @ts-expect-error TS(2322) FIXME: Type 'any' is not assignable to type 'never'.
        indices.current[index] = targets
      } else {
        currentIndex = currentIndices.length
        // @ts-expect-error TS(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
        indices.current.push(targets)
      }

      if (timeline) {
        let currentOffset = offset
        if (stagger && !offset) currentOffset = currentIndex * stagger

        // @ts-expect-error TS(2339) FIXME: Property 'add' does not exist on type '(targets: a... Remove this comment to see the full error message
        timeline.add({ targets, ...itemAnimeOptions }, currentOffset)
      }

      return currentIndex
    },
    [stagger, timeline],
  )

  const remove = useCallback(
    (targets: any) => {
      anime.remove(targets)

      return timeline
    },
    [timeline],
  )

  const ref = useRef()

  useEffect(() => {
    if (!timeline) return

    // @ts-expect-error TS(2322) FIXME: Type '{ timeline: (targets: any) => void; add: (ta... Remove this comment to see the full error message
    ref.current = {
      timeline,
      add,
      remove,
    }
  }, [add, remove, stagger, timeline])

  return {
    add,
    remove,
    timeline,
  }
}

export default useTimelineAnimation
