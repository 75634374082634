import AlertModal from '@nord/ui/src/components/AlertModal'
import BaffleButton from '@nord/ui/src/components/BaffleButton'
import LoadingButton from '@nord/ui/src/components/LoadingButton'
import Text from '@nord/ui/src/components/Text'
import { getConfig } from '@nord/ui/src/configuration'
import useApi, { useApiRequest } from '@nord/ui/src/hooks/useApi'
import classNames from 'classnames'
import camelCase from 'lodash/camelCase'
import React, { useContext, useState } from 'react'

import styles from '../../../../components/ModalPage/CenteredModal.module.scss'

import AccountCreationsContext from './AccountCreationsContext'

const onboardingStateDescriptions = {
  investmentPlanCompleted: 'Mangler godkendelse af risikoprofil',
  riskProfileChosen: 'Mangler færdiggørelse',
  readyToOnboard: 'Under oprettelse hos NORD',
  submittedToSaxo: 'Under oprettelse hos Saxo',
  createdInSaxo: 'Færdigoprettet',
}

const getInvestmentPlanUrl = getConfig('dynamicUrls.onboarding.investmentPlan')

export interface PortfolioRowProps {
  onboardingFlow: 'private' | 'pension' | 'company'
  id: number
  title: string
  readyToOnboardAt?: string
  onboardingState: string
  showDeleteButton: boolean
}

const PortfolioRow = ({
  onboardingFlow,
  id: portfolioId,
  title: portfolioTitle,
  readyToOnboardAt,
  onboardingState,
  showDeleteButton,
}: PortfolioRowProps) => {
  // @ts-expect-error TS(2339) FIXME: Property 'refetchPortfolios' does not exist on typ... Remove this comment to see the full error message
  const { refetchPortfolios } = useContext(AccountCreationsContext)
  const [showAlert, setShowAlert] = useState(false)

  const handleShowAlert = () => setShowAlert(true)
  const handleHideAlert = () => setShowAlert(false)

  const investmentPlanUrl = getInvestmentPlanUrl(onboardingFlow, portfolioId)

  const deletePortfolio = useApi(`/onboarding/${portfolioId}/portfolios`, {
    method: 'DELETE',
    withCredentials: true,
  })

  const { request: callDeletePortfolio, loading: isDeletingPortfolio } =
    useApiRequest(deletePortfolio)

  const handleDeletePortfolio = async () => {
    const { success } = await callDeletePortfolio()

    if (!success) return

    await refetchPortfolios()
  }

  const formattedOnboardingState = camelCase(onboardingState)
  const stateDescription =
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    onboardingStateDescriptions[formattedOnboardingState] || 'Mangler information'

  return (
    <>
      <li className="border-bottom py-2">
        <div className="d-flex flex-column flex-lg-row align-items-center justify-content-between">
          <Text as="div" variant="secondary" className="mb-0 text-center text-lg-left">
            {portfolioTitle}
          </Text>
          <Text as="div" align="center" className="pt-2 pt-lg-0 mb-0">
            {stateDescription}
          </Text>
          <div className="text-center text-lg-right mt-3 mt-lg-0">
            {!readyToOnboardAt && (
              <div className="d-flex flex-column flex-lg-row justify-content-center justify-content-lg-end">
                <BaffleButton as="a" href={investmentPlanUrl} variant="primary">
                  Fortsæt oprettelsen
                </BaffleButton>
                {showDeleteButton && (
                  <BaffleButton
                    onClick={handleShowAlert}
                    variant="secondary"
                    className="mt-2 mt-lg-0 ml-lg-2 bg-transparent text-primary"
                  >
                    Slet oprettelsen
                  </BaffleButton>
                )}
              </div>
            )}
          </div>
        </div>
      </li>
      <AlertModal
        show={showAlert}
        onHide={handleHideAlert}
        title={`Slet ${portfolioTitle}`}
        text="Er du sikker på, at du vil slette denne oprettelse?"
        // @ts-expect-error TS(2739) FIXME: Type '{ ({ icon, loading, disabled, children, ...b... Remove this comment to see the full error message
        acceptButtonComponent={LoadingButton}
        acceptButtonProps={{ loading: isDeletingPortfolio }}
        onAccept={handleDeletePortfolio}
        acceptButtonText="Slet oprettelse"
        backdropClassName={classNames(styles.backdropBackground, styles.warningModal)}
        acceptable
      />
    </>
  )
}

PortfolioRow.defaultProps = {
  readyToOnboardAt: null,
}

export default PortfolioRow
