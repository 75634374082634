import { getPortfolioChartLive } from '@nord/api'
import Card from '@nord/ui/src/components/Card'
import DescriptionList from '@nord/ui/src/components/DescriptionList'
import LoadingIndicator from '@nord/ui/src/components/SectionContainer/LoadingIndicator'
import Text from '@nord/ui/src/components/Text'
import { getConfig } from '@nord/ui/src/configuration'
import { useRequest } from '@nord/ui/src/hooks'
import useObjectSelector from '@nord/ui/src/hooks/useObjectSelector'
import { selectCurrentPortfolio } from '@nord/ui/src/store/current/portfolioId'
import { numberToCurrency } from '@nord/ui/src/utilities/numberFormatter'
import React from 'react'
import Button from 'react-bootstrap/Button'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { Link } from 'react-router-dom'

import CenteredTextSection from '../../../../components/CenteredTextSection'
import useIsPayoutAccount from '../../../../hooks/useIsPayoutAccount'

import SuitabilityContent from './SuitabilityContent'

const getOnboardingResultUrl = getConfig('dynamicUrls.onboarding.investmentPlanResult')

const Information = () => {
  const {
    // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'unknown'.
    id: portfolioId,
    // @ts-expect-error TS(2339) FIXME: Property 'riskScoreTitle' does not exist on type '... Remove this comment to see the full error message
    riskScoreTitle,
    // @ts-expect-error TS(2339) FIXME: Property 'title' does not exist on type 'unknown'.
    title: portfolioTitle,
    // @ts-expect-error TS(2339) FIXME: Property 'registrationNumber' does not exist on ty... Remove this comment to see the full error message
    registrationNumber,
    // @ts-expect-error TS(2339) FIXME: Property 'saxoAccountNumber' does not exist on typ... Remove this comment to see the full error message
    saxoAccountNumber,
    // @ts-expect-error TS(2339) FIXME: Property 'onboardingFlow' does not exist on type '... Remove this comment to see the full error message
    onboardingFlow,
    // @ts-expect-error TS(2339) FIXME: Property 'cashInDkk' does not exist on type 'unkno... Remove this comment to see the full error message
    cashInDkk,
  } = useObjectSelector(selectCurrentPortfolio)

  const changeRiskProfileUrl = getOnboardingResultUrl(onboardingFlow, portfolioId)

  const isPayoutAccount = useIsPayoutAccount()

  const portfolio = useRequest(getPortfolioChartLive, [{ portfolioId, chartType: 'values' }])

  return (
    <>
      <Text as="h4" align="center" className="mt-3">
        {portfolioTitle}
      </Text>
      <div className="text-center mb-3">
        <Link to={`/portefoeljer/${portfolioId}/rediger`}>(Omdøb portefølje)</Link>
      </div>
      <Card className="shadow mb-3 mx-lg-5">
        <Card.Body className="mx-lg-5">
          {!portfolio.isSuccess ? (
            <LoadingIndicator />
          ) : (
            <DescriptionList className="text-center text-lg-left">
              <DescriptionList.Item
                label="Samlet værdi"
                value={numberToCurrency(portfolio.data.value)}
                xs={12}
                // @ts-expect-error TS(2322) FIXME: Type '{ label: string; value: string; xs: number; ... Remove this comment to see the full error message
                lg={6}
              />
              <DescriptionList.Item
                label="Heraf kontantbeholdning"
                value={numberToCurrency(cashInDkk)}
                xs={12}
                // @ts-expect-error TS(2322) FIXME: Type '{ label: string; value: string; xs: number; ... Remove this comment to see the full error message
                lg={6}
              />
              {!isPayoutAccount && (
                // @ts-expect-error TS(2322) FIXME: Type '{ label: string; value: any; xs: number; lg:... Remove this comment to see the full error message
                <DescriptionList.Item label="Risikoprofil" value={riskScoreTitle} xs={12} lg={6} />
              )}
              <DescriptionList.Item
                label="Reg.- og kontonummer"
                value={
                  registrationNumber && saxoAccountNumber
                    ? `${registrationNumber} - ${saxoAccountNumber}`
                    : '-'
                }
                xs={12}
                // @ts-expect-error TS(2322) FIXME: Type '{ label: string; value: string; xs: number; ... Remove this comment to see the full error message
                lg={6}
              />
            </DescriptionList>
          )}
        </Card.Body>
      </Card>
      {!isPayoutAccount && (
        <CenteredTextSection className="py-1">
          <Text as="p" className="mb-0">
            Du kan til enhver tid se din investeringsplan ved at følge linket nedenfor. Her kan du
            også ændre din risikoprofil.
          </Text>
          <Button variant="link" as="a" href={changeRiskProfileUrl}>
            Se din investeringsplan eller skift din risikoprofil
          </Button>
        </CenteredTextSection>
      )}
      <SuitabilityContent />
    </>
  )
}

export default Information
