import Text from '@nord/ui/src/components/Text'
import { numberToCurrency } from '@nord/ui/src/utilities/numberFormatter'
import classNames from 'classnames'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import { useAllocationContext } from './AllocationContext'
import Dot from './Dot'

const List = () => {
  // @ts-expect-error TS(2339) FIXME: Property 'allocationData' does not exist on type '... Remove this comment to see the full error message
  const { chartData } = useAllocationContext()

  return (
    <Col xs={12} lg={6} className="text-left my-auto">
      <ul className="list-unstyled">
        {chartData.map(
          (
            {
              category,
              label,
              danishName,
              humanizedPercentage,
              amount,
              url,
              legalName,
              color,
            }: any,
            index: any,
          ) => {
            const isLast = index + 1 === chartData.length
            const legalElement =
              category === 'cash' ? (
                <Text align="left" transform="capitalize" variant="secondary">
                  {legalName}
                </Text>
              ) : (
                <Text
                  as="a"
                  href={url}
                  target="_blank"
                  align="left"
                  transform="capitalize"
                  variant="secondary"
                >
                  {legalName}
                </Text>
              )

            return (
              <Row
                as="li"
                className={classNames('py-2', {
                  'border-bottom': !isLast,
                })}
                key={label}
              >
                <Col lg={9}>
                  <div className="d-flex flex-row align-items-center">
                    <Dot fill={color} width="10px" height="10px" />
                    <Text as="p" align="left" className="pl-2 mb-0">
                      {danishName}
                    </Text>
                  </div>
                  {legalElement}
                </Col>
                <Col lg={3}>
                  <Text as="div" align="right">
                    {humanizedPercentage}
                  </Text>
                  <Text as="div" align="right" variant="secondary">
                    {numberToCurrency(amount)}
                  </Text>
                </Col>
              </Row>
            )
          },
        )}
      </ul>
    </Col>
  )
}

export default List
