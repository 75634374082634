import classNames from 'classnames'
import React from 'react'
import { NavItem as BootstrapNavItem } from 'react-bootstrap'

export interface Props {
  className?: string
}

const NavItem = ({ className, ...otherProps }: Props) => (
  <BootstrapNavItem className={classNames('nav-item', className)} {...otherProps} />
)

NavItem.defaultProps = {
  className: undefined,
}

export default NavItem
