// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { useRouteMatch } from 'react-router-dom'

const validChartTypes = ['historisk', 'tidsvaegtet', 'afkast'] as const

const useChartType = () => {
  const routeMatch = useRouteMatch('/portefoeljer/:id/:chartType')

  if (!(routeMatch && routeMatch.params.chartType)) return undefined

  const { chartType } = routeMatch.params
  if (!validChartTypes.includes(chartType)) return undefined

  return chartType as (typeof validChartTypes)[number]
}

export default useChartType
