import useObjectSelector from '@nord/ui/src/hooks/useObjectSelector'
import { selectPortfolioById } from '@nord/ui/src/store/current/portfolios'

const warningText =
  'Vær opmærksom på, at pensionskonti har en årlig grænse for indbetalinger, og den er forskellig for hver type af pension. Sørg for, at du kan indsætte penge på din pensionskonto uden at overskride den årlige grænse. Denne handling kan ikke fortrydes.'

const usePensionWarning = (id: any) => {
  // @ts-expect-error TS(2339) FIXME: Property 'pensionDepot' does not exist on type 'un... Remove this comment to see the full error message
  const { pensionDepot: pension } = useObjectSelector(selectPortfolioById(id))

  if (!pension) return undefined

  return warningText
}

export default usePensionWarning
