import IconButton from '@nord/ui/src/components/IconButton'
import Text from '@nord/ui/src/components/Text'
import useQuery from '@nord/ui/src/hooks/useQuery'
import React from 'react'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import ArrowRight from 'react-feather/dist/icons/arrow-right'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { Link } from 'react-router-dom'

import CenteredTextSection from '../../../../components/CenteredTextSection'

const defaultError =
  'Der opstod et problem med dit udbetalingsanmodning. Prøv igen, eller kontakt os hvis du fortsat oplever problemer.'

const ErrorPage = () => {
  const error = useQuery('error')

  return (
    <CenteredTextSection>
      <Text as="p">{error || defaultError}</Text>
      <IconButton
        className="mb-3"
        buttonAs={Link}
        icon={ArrowRight}
        to="/konto/kontant_udbetaling/verificer"
      >
        Prøv igen
      </IconButton>
    </CenteredTextSection>
  )
}

export default ErrorPage
