import classNames from 'classnames'
import { isString, isNil } from 'lodash'
import React from 'react'

import styles from './AnimatedLink.module.scss'
import InternalLink from './InternalLink'

export interface Props {
  label?: string
  children?: string
  className?: string
  as?: React.ReactElement
  disabled?: boolean
  variant?: 'dark' | 'light'
}

const AnimatedLink = ({
  label,
  children,
  className,
  as: Component,
  variant,
  disabled,
  ...otherProps
}: Props) => {
  // @ts-expect-error TS(7023) FIXME: 'renderChildren' implicitly has return type 'any' ... Remove this comment to see the full error message
  const renderChildren = (childCollection: any) =>
    React.Children.map(childCollection, (child) => {
      if (isNil(child)) {
        return null
      }

      if (child.type === React.Fragment) {
        return renderChildren(child.props.children)
      }

      if (isString(child)) return child

      return null
    })

  return (
    // @ts-expect-error TS(2604) FIXME: JSX element type 'Component' does not have any con... Remove this comment to see the full error message
    <Component
      className={classNames(variant, `link-${variant}`, styles.animatedLink, className)}
      {...otherProps}
    >
      <span
        className={classNames(variant, styles.animatedLinkText, { disabled })}
        data-name={label || renderChildren(children)}
      >
        {label || children}
      </span>
    </Component>
  )
}

AnimatedLink.defaultProps = {
  as: InternalLink,
  children: undefined,
  className: undefined,
  disabled: undefined,
  label: undefined,
  variant: 'light',
}

export default AnimatedLink
