import PropTypes from 'prop-types'

import styleConfiguration from '../../configuration/styleConfiguration'

const { variants, breakpoints } = styleConfiguration

export const bootstrapVariant = (options = {}) => {
  // @ts-expect-error TS(2339) FIXME: Property 'extraVariants' does not exist on type '{... Remove this comment to see the full error message
  const { extraVariants } = options

  let list = [...variants]
  if (extraVariants !== undefined) list = list.concat(extraVariants)

  return PropTypes.oneOf(list)
}

export const bootstrapSize = PropTypes.oneOf(Object.keys(breakpoints))
