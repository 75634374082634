import React, { useCallback, useMemo, useState } from 'react'

import CenteredModal from './CenteredModal'
import ModalPageContext from './ModalPageContext'

export interface Props {
  children?: React.ReactNode
  header?: React.ReactNode
  backUrl?: string
}

const ModalPage = ({ children, header, backUrl, ...otherProps }: Props) => {
  const [withWarning, setWithWarning] = useState(false)

  const handleChangeWithWarning = useCallback((value: any) => setWithWarning(value), [])

  const context = useMemo(
    () => ({
      withWarning,
      onChangeWithWarning: handleChangeWithWarning,
    }),
    [handleChangeWithWarning, withWarning],
  )

  return (
    <ModalPageContext.Provider value={context}>
      {/* @ts-expect-error TS(2322) FIXME: Type '{ children: ReactNode; backUrl: string | und... Remove this comment to see the full error message */}
      <CenteredModal backUrl={backUrl} header={header} show {...otherProps}>
        {children}
      </CenteredModal>
    </ModalPageContext.Provider>
  )
}

ModalPage.defaultProps = {
  backUrl: undefined,
  children: undefined,
  header: undefined,
}

export default ModalPage
