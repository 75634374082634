import Card from '@nord/ui/src/components/Card'
import React from 'react'
import Col from 'react-bootstrap/Col'

export interface Props {
  children: React.ReactNode
}

const FormPageWrapper = ({ children }: Props) => (
  <Col xs={12} md={10} lg={8} xl={6} className="px-2 px-sm-3">
    <Card>
      <Card.Body>{children}</Card.Body>
    </Card>
  </Col>
)

export default FormPageWrapper
