import Loader from '@nord/ui/src/components/Loader'
import Text from '@nord/ui/src/components/Text'
import useApi, { useApiRequest } from '@nord/ui/src/hooks/useApi'
import formatDate from '@nord/ui/src/utilities/formatDate'
import { numberToCurrency } from '@nord/ui/src/utilities/numberFormatter'
import isEmpty from 'lodash/isEmpty'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import CenteredTextSection from '../../../../components/CenteredTextSection'
import usePortfolioId from '../../../../hooks/usePortfolioId'

const Transactions = () => {
  const portfolioId = usePortfolioId()

  const fetchTransactions = useApi(`/portfolios/${portfolioId}/transactions`, {
    method: 'GET',
    withCredentials: true,
  })

  const { data: transactionsData, loading: loadingTransactionData } = useApiRequest(
    fetchTransactions,
    { autoCall: true },
  )

  return (
    <>
      <Text as="h4" align="center" className="my-3">
        Kontobevægelser
      </Text>
      {loadingTransactionData ? (
        <Loader className="text-center font-size-xxl" />
      ) : (
        <ul className="list-unstyled px-2">
          {isEmpty(transactionsData) ? (
            <CenteredTextSection>Der er ingen kontobevægelser at vise.</CenteredTextSection>
          ) : (
            transactionsData.map(({ id, description, date, amount, saldo }: any) => (
              <Row key={id} as="li" className="border-bottom py-2">
                <Col>
                  <Text as="div" align="left" className="mb-0">
                    {description}
                  </Text>
                  <Text as="div" align="left" variant="secondary">
                    {formatDate(date)}
                  </Text>
                </Col>
                <Col>
                  <Text as="div" align="right" className="mb-0">
                    {numberToCurrency(amount)}
                  </Text>
                  <Text as="div" align="right" variant="secondary">
                    Saldo: {numberToCurrency(saldo)}
                  </Text>
                </Col>
              </Row>
            ))
          )}
        </ul>
      )}
    </>
  )
}

export default Transactions
