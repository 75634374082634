import styleConfiguration from '@nord/ui/src/configuration/styleConfiguration'
import groupBy from 'lodash/groupBy'

const { blues, themeColors, greens } = styleConfiguration

const getColor = (category: string, index: number) => {
  if (category === 'cash') return themeColors.warning
  if (category === 'stocks') return blues[(index + 1) * 100]

  return greens[(index + 1) * 100]
}

// eslint-disable-next-line import/prefer-default-export
export const formatDataForChart = (data: any) => {
  if (!data) return undefined

  const chartData = Object.values(groupBy(data, 'mainCategory')).flatMap((allocations) =>
    allocations.map((allocation, index) => ({
      ...allocation,
      name: allocation.danishName,
      value: allocation.percentage,
      amount: allocation.value,
      color: getColor(allocation.category, index),
    })),
  )

  return chartData
}
