import classNames from 'classnames'
import React from 'react'

import styles from './index.module.scss'

export interface Props {
  children: React.ReactNode
  className?: string
}

const CenteredTextSection = ({ children, className }: Props) => (
  <div className={classNames('text-center', styles.centeredTextSection, className)}>{children}</div>
)

CenteredTextSection.defaultProps = {
  className: undefined,
}

export default CenteredTextSection
