import Card from '@nord/ui/src/components/Card'
import { numberToPercent } from '@nord/ui/src/utilities/numberFormatter'
import React from 'react'
import { useResizeDetector } from 'react-resize-detector/build/withPolyfill'
import { Pie, PieChart, Cell, Tooltip } from 'recharts'

import { useAllocationContext } from './AllocationContext'

const Chart = () => {
  // @ts-expect-error TS(2339) FIXME: Property 'chartData' does not exist on type 'unkno... Remove this comment to see the full error message
  const { chartData } = useAllocationContext()

  const { width, ref } = useResizeDetector({
    handleHeight: false,
    refreshMode: 'debounce',
    refreshRate: 100,
  })

  return (
    <div ref={ref}>
      <PieChart width={width} height={width} className="mx-auto mx-lg-0">
        {/* @ts-expect-error TS(2741) FIXME: Property 'dataKey' is missing in type '{ children:... Remove this comment to see the full error message */}
        <Pie data={chartData} isAnimationActive={false} startAngle={-270}>
          {chartData.map((entry: any) => (
            <Cell fill={entry.color} stroke={entry.color} />
          ))}
        </Pie>
        <Tooltip
          cursor={false}
          // eslint-disable-next-line react/no-unstable-nested-components
          content={({ payload }) => {
            if (!payload) return null
            if (!payload.length) return null
            if (!payload[0].payload) return null

            return (
              <Card>
                <Card.Body className="p-2">
                  <Card.Title className="font-size-md mb-0">{payload[0].name}</Card.Title>
                  <Card.Text className="font-size-sm text-center mb-0">
                    {numberToPercent(payload[0].value, {
                      maximumFractionDigits: 2,
                    })}
                  </Card.Text>
                </Card.Body>
              </Card>
            )
          }}
        />
      </PieChart>
    </div>
  )
}

export default Chart
