import Loader from '@nord/ui/src/components/Loader'
import Text from '@nord/ui/src/components/Text'
import { numberToCurrency } from '@nord/ui/src/utilities/numberFormatter'
import React from 'react'

export interface Props {
  liveData?: {
    value: number
    returnInDkk: number
  }
  isLoading: boolean
}

const HistoricalValues = ({ liveData, isLoading, ...otherProps }: Props) => (
  <div {...otherProps}>
    <Text as="h6" variant="deep-dark" align="center">
      Porteføljeværdi
    </Text>
    {/* @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'. */}
    {isLoading || liveData.value === undefined || liveData.returnInDkk === undefined ? (
      <Loader className="font-size-xxl text-white text-center" />
    ) : (
      <>
        <Text as="h1" align="center" variant="primary" className="mb-0">
          {/* @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'. */}
          {numberToCurrency(liveData.value)}
        </Text>
        <Text as="h6" align="center">
          <span className="text-deep-dark">Afkast </span>
          {/* @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'. */}
          <Text variant="primary">{numberToCurrency(liveData.returnInDkk)}</Text>
        </Text>
      </>
    )}
  </div>
)

HistoricalValues.defaultProps = {
  liveData: undefined,
}

export default HistoricalValues
