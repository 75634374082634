import Card from '@nord/ui/src/components/Card'
import DescriptionList from '@nord/ui/src/components/DescriptionList'
import IconButton from '@nord/ui/src/components/IconButton'
import Text from '@nord/ui/src/components/Text'
import { selectCurrentUser } from '@nord/ui/src/store/current/user'
import React from 'react'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import SettingsIcon from 'react-feather/dist/icons/settings'
import { useSelector } from 'react-redux'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { Link } from 'react-router-dom'

const Information = () => {
  const { firstName, lastName, email, phone, phonePrefix, accountType, companyName } =
    useSelector(selectCurrentUser)

  const isCompany = accountType === 'company_account'

  return (
    <>
      <Text as="h4" align="center" className="my-3">
        Mine informationer
      </Text>
      <Card className="mb-3 mx-lg-5">
        <Card.Body className="mx-lg-5">
          <DescriptionList className="text-center text-lg-left">
            {isCompany && (
              // @ts-expect-error TS(2322) FIXME: Type '{ label: string; value: any; xs: number; lg:... Remove this comment to see the full error message
              <DescriptionList.Item label="Virksomhedsnavn" value={companyName} xs={12} lg={6} />
            )}
            {/* @ts-expect-error TS(2322) FIXME: Type '{ label: string; value: string; xs: number; ... Remove this comment to see the full error message */}
            <DescriptionList.Item label="Navn" value={`${firstName} ${lastName}`} xs={12} lg={6} />
            {/* @ts-expect-error TS(2322) FIXME: Type '{ label: string; value: any; xs: number; lg:... Remove this comment to see the full error message */}
            <DescriptionList.Item label="Email" value={email} xs={12} lg={6} />
            <DescriptionList.Item
              label="Telefon"
              value={`+${phonePrefix} ${phone}`}
              xs={12}
              // @ts-expect-error TS(2322) FIXME: Type '{ label: string; value: string; xs: number; ... Remove this comment to see the full error message
              lg={6}
            />
          </DescriptionList>
        </Card.Body>
      </Card>
      <div className="d-flex justify-content-center my-4">
        <IconButton icon={SettingsIcon} as={Link} to="/konto/adgangskode">
          Skift adgangskode
        </IconButton>
      </div>
    </>
  )
}

export default Information
