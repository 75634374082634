import React from 'react'

import AccountValue from './AccountValue'
import HistoricalReturns from './HistoricalReturns'
import HistoricalValues from './HistoricalValues'
import TimeWeightedReturns from './TimeWeightedReturns'
import useHeaderType from './useHeaderType'

const headerTypes = {
  timeWeighted: TimeWeightedReturns,
  returns: HistoricalReturns,
  values: HistoricalValues,
  accountValue: AccountValue,
}

const Header = (props: any) => {
  const headerType = useHeaderType()

  const HeaderComponent = headerTypes[headerType]

  return <HeaderComponent {...props} />
}

export default Header
