import React from 'react'

import FormPageWrapper from '../FormPageWrapper'

import ResetPasswordForm from './ResetPasswordForm'

const ResetPasswordPage = () => (
  <FormPageWrapper>
    <ResetPasswordForm />
  </FormPageWrapper>
)

export default ResetPasswordPage
