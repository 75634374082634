import FullScreenSection from '@nord/ui/src/components/FullScreenSection'
import NordHeader from '@nord/ui/src/components/Header'
import Loader from '@nord/ui/src/components/Loader'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Navbar from 'react-bootstrap/Navbar'
import Row from 'react-bootstrap/Row'

const LoadingPage = () => (
  <FullScreenSection topSection>
    <NordHeader mobile>
      <Navbar role="banner">
        <NordHeader.Logo />
      </Navbar>
    </NordHeader>
    <Row>
      <Col>
        <Loader className="font-size-xxl" />
      </Col>
    </Row>
  </FullScreenSection>
)

export default LoadingPage
