import Card from '@nord/ui/src/components/Card'
import DescriptionList from '@nord/ui/src/components/DescriptionList'
import Text from '@nord/ui/src/components/Text'
import { numberToCurrency } from '@nord/ui/src/utilities/numberFormatter'
import React from 'react'

import CenteredTextSection from '../../../../components/CenteredTextSection'

import labelDescriptions from './labelDecriptions'

export interface Props {
  data: any // TODO: PropTypes.shape()
}

const ConfirmationPage = ({ data }: Props) => {
  const { amount, transactionMessage, accountFrom, accountTo } = data

  return (
    <CenteredTextSection>
      <Text as="p">
        Vi har modtaget din overførselsanmodning og vil behandle den hurtigst muligt. Denne
        overførsel kan tage op til 5 bankdage, men det går ofte hurtigere.
      </Text>
      <Card className="mb-3 mx-lg-5">
        <Card.Body className="mx-lg-5">
          <DescriptionList className="text-center text-lg-left">
            <DescriptionList.Item
              label={labelDescriptions.portfolioId}
              value={accountFrom}
              xs={12}
              // @ts-expect-error TS(2322) FIXME: Type '{ label: string; value: any; xs: number; lg:... Remove this comment to see the full error message
              lg={6}
            />
            <DescriptionList.Item
              label={labelDescriptions.externalAccountId}
              value={accountTo}
              xs={12}
              // @ts-expect-error TS(2322) FIXME: Type '{ label: string; value: any; xs: number; lg:... Remove this comment to see the full error message
              lg={6}
            />
            <DescriptionList.Item
              label={labelDescriptions.amount}
              value={numberToCurrency(amount)}
              xs={12}
              // @ts-expect-error TS(2322) FIXME: Type '{ label: string; value: string; xs: number; ... Remove this comment to see the full error message
              lg={6}
            />
            {transactionMessage && (
              <DescriptionList.Item
                label={labelDescriptions.transactionMessage}
                value={transactionMessage}
                xs={12}
                // @ts-expect-error TS(2322) FIXME: Type '{ label: string; value: any; xs: number; lg:... Remove this comment to see the full error message
                lg={6}
              />
            )}
          </DescriptionList>
        </Card.Body>
      </Card>
    </CenteredTextSection>
  )
}

export default ConfirmationPage
