import useObjectSelector from '@nord/ui/src/hooks/useObjectSelector'
import { selectCurrentPortfolio } from '@nord/ui/src/store/current/portfolioId'
import { useMemo } from 'react'

import { useAppContext } from '../../../../context/AppContext'
import useIsPayoutAccount from '../../../../hooks/useIsPayoutAccount'

const useHeaderType = () => {
  const { chartType } = useAppContext()
  // @ts-expect-error TS(2339) FIXME: Property 'appState' does not exist on type 'unknow... Remove this comment to see the full error message
  const { appState: portfolioAppState } = useObjectSelector(selectCurrentPortfolio)
  const isPayoutAccount = useIsPayoutAccount()
  const isInvested = portfolioAppState === 'invested'

  const headerType = useMemo(() => {
    if (isPayoutAccount || !isInvested) return 'accountValue'

    return chartType
  }, [chartType, isInvested, isPayoutAccount])

  return headerType
}

export default useHeaderType
