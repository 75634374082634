import AlertModal from '@nord/ui/src/components/AlertModal'
import extractProps from '@nord/ui/src/utilities/extractProps'
import classNames from 'classnames'
import React, { useContext, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { useHistory } from 'react-router-dom'

import styles from './CenteredModal.module.scss'
import ModalPageContext from './ModalPageContext'

const modalHeaderPropKeys = ['closeButton', 'closeLabel', 'closeVariant', 'modalHeaderClassName']

export interface Props {
  backUrl?: string
  children?: React.ReactNode
  header?: React.ReactNode
}

const CenteredModal = ({ header, children, backUrl, ...otherProps }: Props) => {
  const history = useHistory()

  const [modalHeaderProps, modalProps] = extractProps(otherProps, modalHeaderPropKeys)

  // @ts-expect-error TS(2339) FIXME: Property 'withWarning' does not exist on type 'unk... Remove this comment to see the full error message
  const { withWarning } = useContext(ModalPageContext)
  const [showAlert, setShowAlert] = useState(false)

  const handleHideAlert = () => setShowAlert(false)

  const handlePageLeave = () => {
    if (!backUrl) history.goBack()
    else history.push(backUrl)
  }

  const handleHide = () => {
    if (withWarning) {
      setShowAlert(true)

      return
    }

    handlePageLeave()
  }

  return (
    <>
      <Modal
        {...modalProps}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        onHide={handleHide}
        backdropClassName={styles.backdropBackground}
        contentClassName={styles.modalContentBorder}
        dialogClassName={styles.customModal}
        centered
      >
        {/* @ts-expect-error TS(2322) FIXME: Type 'unknown' is not assignable to type 'string |... Remove this comment to see the full error message */}
        <Modal.Header className={modalHeaderProps.modalHeaderClassName} {...modalHeaderProps}>
          {header}
        </Modal.Header>
        <Modal.Body className={styles.modalHeight}>{children}</Modal.Body>
      </Modal>
      <AlertModal
        show={showAlert}
        onHide={handleHideAlert}
        // @ts-expect-error TS(2322) FIXME: Type '{ show: boolean; onHide: () => void; backdro... Remove this comment to see the full error message
        backdrop="static"
        title="Ændringer er ikke gemt"
        text="Er du sikker på, at du vil forlade denne side? Ændringer, du har foretaget, vil ikke blive gemt."
        onAccept={handlePageLeave}
        backdropClassName={classNames(styles.backdropBackground, styles.warningModal)}
        acceptable
      />
    </>
  )
}

CenteredModal.defaultProps = {
  backUrl: undefined,
  children: undefined,
  header: undefined,
}

export default CenteredModal
