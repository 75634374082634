/* eslint-disable import/prefer-default-export */
import type { SnakeToCamelCaseNested } from '@nord/ui/src'
import { getApiClient } from '@nord/ui/src'
import { formatRequestData } from '@nord/ui/src/hooks/useApi/formatData'
import difference from 'lodash/difference'
import pick from 'lodash/pick'

import type { paths } from './schema'

const axiosClient = getApiClient()

const METHODS = new Set(['get', 'post', 'put', 'delete', 'patch'])

function httpMethod(method: unknown): method is 'get' | 'post' | 'put' | 'delete' | 'patch' {
  return typeof method === 'string' && METHODS.has(method)
}

type KeyToPath<T extends string> = T extends `${infer Head}{${infer Key}}${infer Tail}`
  ? `${Head}:${Key}${KeyToPath<Tail>}`
  : T

export function openApiFactory<P extends keyof paths, M extends keyof paths[P]>(key: P, method: M) {
  type Method = paths[P][M]

  type MethodPathParameters = Method extends {
    parameters: { path: Record<string, unknown> }
  }
    ? SnakeToCamelCaseNested<Method['parameters']['path']>
    : never

  type MethodQueryParameters = Method extends {
    parameters: { query: Record<string, unknown> }
  }
    ? SnakeToCamelCaseNested<Method['parameters']['query']>
    : never

  type MethodRequestBody = Method extends {
    requestBody?: { content: { 'application/json': infer RequestBody } }
  }
    ? SnakeToCamelCaseNested<RequestBody>
    : never

  type MethodResponses = Method extends {
    responses:
      | { 200: { content: { 'application/json': infer ResponseSuccess } } }
      | { 201: { content: { 'application/json': infer ResponseSuccess } } }
  }
    ? SnakeToCamelCaseNested<ResponseSuccess>
    : unknown

  type Params = [MethodPathParameters, MethodQueryParameters] extends [never, never]
    ? never
    : [MethodPathParameters, MethodQueryParameters] extends [MethodPathParameters, never]
    ? MethodPathParameters
    : [MethodPathParameters, MethodQueryParameters] extends [never, MethodQueryParameters]
    ? MethodQueryParameters
    : MethodPathParameters & MethodQueryParameters

  type Parameters = [Params, MethodRequestBody] extends [never, never]
    ? []
    : [Params, MethodRequestBody] extends [never, MethodRequestBody]
    ? [{ body: MethodRequestBody }]
    : [Partial<Params>, MethodRequestBody] extends [Params, never]
    ? [{ params?: Params }?]
    : [Params, MethodRequestBody] extends [Params, never]
    ? [{ params: Params }]
    : [Partial<Params>, MethodRequestBody] extends [Params, MethodRequestBody]
    ? [{ params?: Params; body: MethodRequestBody }]
    : [{ params: Params; body: MethodRequestBody }]

  const regex = /\{(\w+)\}/g
  const parameters = (key.match(regex) ?? []).map((match) => match.slice(1, -1))

  const fn = async (...args: Parameters) => {
    const { body, params } = formatRequestData({ body: undefined, params: {}, ...args.at(0) })
    const query = difference(Object.keys(params), parameters)
    const urlSearchParams = pick(params, query)

    const path = Object.entries(params).reduce(
      (p, [k, v]) => p.replace(`{${k}}`, String(v)),
      key as string,
    )

    if (!httpMethod(method)) {
      throw new Error('Something went wrong')
    }

    if (method === 'get') {
      return axiosClient
        .get<MethodResponses>(path, {
          params: urlSearchParams,
          withCredentials: true,
        })
        .then(({ data }) => data)
    }

    return axiosClient[method]<MethodResponses>(path, body, {
      params: urlSearchParams,
      withCredentials: true,
    }).then(({ data }) => data)
  }

  const path = key.replaceAll('{', ':').replaceAll('}', '') as KeyToPath<typeof key>

  return Object.assign(fn as typeof fn & { responseBody: MethodResponses }, {
    key,
    path,
    query(...args: Parameters) {
      return {
        queryKey: [key, ...args] as const,
        queryFn: () => fn(...args),
      }
    },
  })
}
