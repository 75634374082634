import ApplicationWrapper from '@nord/ui/src/components/ApplicationWrapper'
import CookieConsent from '@nord/ui/src/components/ApplicationWrapper/plugins/CookieConsent'
import EnvironmentBar from '@nord/ui/src/components/ApplicationWrapper/plugins/EnvironmentBar'
import ErrorBoundary from '@nord/ui/src/components/ApplicationWrapper/plugins/ErrorBoundary'
import Intercom from '@nord/ui/src/components/ApplicationWrapper/plugins/Intercom'
import PageLayout from '@nord/ui/src/components/ApplicationWrapper/plugins/PageLayout'
import ReduxProvider from '@nord/ui/src/components/ApplicationWrapper/plugins/ReduxProvider'
import Router from '@nord/ui/src/components/ApplicationWrapper/plugins/Router'
import ToastNotifications from '@nord/ui/src/components/ApplicationWrapper/plugins/ToastNotifications'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import React from 'react'

import PagesWrapper from './components/PagesWrapper'

const queryClient = new QueryClient()

const App = () => (
  // @ts-expect-error TS(2786) FIXME: 'ApplicationWrapper' cannot be used as a JSX compo... Remove this comment to see the full error message
  <ApplicationWrapper
    plugins={[
      // @ts-expect-error TS(2322) FIXME: Type '{ ({ linkComponent, enablePageView, children... Remove this comment to see the full error message
      Router,
      // @ts-expect-error TS(2322) FIXME: Type '({ children }: ErrorBoundaryProps) => JSX.El... Remove this comment to see the full error message
      ErrorBoundary,
      // @ts-expect-error TS(2322) FIXME: Type '{ ({ children, extraReducers, ...otherProps ... Remove this comment to see the full error message
      ReduxProvider,
      // @ts-expect-error TS(2322) FIXME: Type '{ ({ children }: Props): ReactNode; displayN... Remove this comment to see the full error message
      Intercom,
      // @ts-expect-error TS(2322) FIXME: Type 'ComponentType<ToastProviderProps>' is not as... Remove this comment to see the full error message
      ToastNotifications,
      // @ts-expect-error TS(2322) FIXME: Type '{ ({ children, onAccept }: Props): string | ... Remove this comment to see the full error message
      CookieConsent,
      // @ts-expect-error TS(2322) FIXME: Type '{ ({ children, className, position, ...other... Remove this comment to see the full error message
      EnvironmentBar,
      // @ts-expect-error TS(2322) FIXME: Type '{ ({ children, ...otherProps }: Props): JSX.... Remove this comment to see the full error message
      PageLayout,
    ]}
    pluginsProps={{
      PageLayout: {
        className: 'p-0',
        fluid: true,
      },
      ReduxProvider: {
        loadPortfolios: 'ready_to_onboard',
      },
    }}
  >
    <QueryClientProvider client={queryClient}>
      <PagesWrapper />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </ApplicationWrapper>
)

export default App
