import Form from '@nord/ui/src/components/Form'
import Icon from '@nord/ui/src/components/Icon'
import Text from '@nord/ui/src/components/Text'
import {
  selectCurrentPortfolio,
  updateCurrentPortfolio,
} from '@nord/ui/src/store/current/portfolioId'
import React from 'react'
import Button from 'react-bootstrap/Button'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import ArrowLeft from 'react-feather/dist/icons/arrow-left'
import { useDispatch, useSelector } from 'react-redux'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { useHistory } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'

import CenteredTextSection from '../../../../components/CenteredTextSection'
import usePageLeaveWarning from '../../../../hooks/usePageLeaveWarning'

const TitleChange = () => {
  const dispatch = useDispatch()
  const { id: portfolioId, title: portfolioTitle } = useSelector(selectCurrentPortfolio)

  usePageLeaveWarning()

  const history = useHistory()
  const { addToast } = useToasts()

  const handleGoBack = () => history.push(`/portefoeljer/${portfolioId}`)

  const handleSubmit = async ({ title }: any) => {
    await dispatch(
      updateCurrentPortfolio({
        title,
      }),
    )

    addToast('Din portefølje er nu omdøbt.', {
      appearance: 'info',
      autoDismiss: true,
    })
    handleGoBack()
  }

  return (
    <>
      <div className="d-flex align-items-center">
        <Button variant="link-dark" className="px-0 my-auto mr-auto">
          {/* @ts-expect-error TS(2322) FIXME: Type '{ icon: any; onClick: () => any; size: strin... Remove this comment to see the full error message */}
          <Icon icon={ArrowLeft} onClick={handleGoBack} size="20" />
        </Button>
        <Text as="h4" align="center" className="my-3 mr-auto">
          Omdøb portefølje
        </Text>
      </div>
      <CenteredTextSection>
        <Form
          onSubmit={handleSubmit}
          initialValues={{ title: portfolioTitle }}
          // @ts-expect-error TS(2322) FIXME: Type '{ children: Element[]; onSubmit: ({ title }:... Remove this comment to see the full error message
          className="text-left"
        >
          <Form.FieldGroup label="Portefølje titel" name="title">
            {/* @ts-expect-error TS(2322) FIXME: Type '{ autoFocus: true; }' is not assignable to t... Remove this comment to see the full error message */}
            <Form.Field autoFocus />
          </Form.FieldGroup>
          <div className="d-flex justify-content-center my-4">
            {/* @ts-expect-error TS(2322) FIXME: Type '{ children: string; variant: string; }' is n... Remove this comment to see the full error message */}
            <Form.SubmitButton variant="primary">Omdøb</Form.SubmitButton>
          </div>
        </Form>
      </CenteredTextSection>
    </>
  )
}

export default TitleChange
