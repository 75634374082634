import { selectCurrentPortfolioId } from '@nord/ui/src/store/current/portfolioId'
import { useSelector } from 'react-redux'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { useHistory } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'

import useUpdateUserAndPortfolio from './useUpdateUserAndPortfolio'

const useFinishSustainabilityUpdate = () => {
  const history = useHistory()
  const portfolioId = useSelector(selectCurrentPortfolioId)
  const { addToast } = useToasts()
  const updateUserAndPortfolio = useUpdateUserAndPortfolio()

  const finishSustainabilityUpdate = (data: any) => {
    updateUserAndPortfolio(data)
    history.push(`/portefoeljer/${portfolioId}/anbefaling`)

    addToast('Vi har opdateret din bæredygtighedspræferencer.', {
      appearance: 'info',
      autoDismiss: true,
    })
  }

  return finishSustainabilityUpdate
}

export default useFinishSustainabilityUpdate
