import { getConfig } from '@nord/ui/src/configuration'
import { formatRequestData } from '@nord/ui/src/hooks/useApi/formatData'
import { getApiClient } from '@nord/ui/src/utilities'
import { isUndefined, mapValues, omitBy } from 'lodash'

const apiClient = getApiClient({ baseURL: getConfig('domains.backend') })

type Params = Record<string, string | number | boolean | undefined>

async function get<T>(path: string, searchParams: Params = {}) {
  const omittedOptions = omitBy(searchParams ?? {}, isUndefined)
  const init = formatRequestData(mapValues(omittedOptions, String))

  const { data } = await apiClient.request({
    url: `${path}?${new URLSearchParams(init)}`,
    withCredentials: true,
  })

  return data as T
}

export default get
