import React, { useState } from 'react'

import CustomDateRangePopover from './CustomDateRangePopover'
import DateButton from './DateButton'

const CustomDateRangeButton = ({ ...props }) => {
  const [showPopover, setShowPopover] = useState(false)
  const [target, setTarget] = useState()

  const handleShowPopover = (event: any) => {
    setShowPopover(true)
    setTarget(event.target)
  }

  const handleClosePopover = () => setShowPopover(false)

  return (
    <>
      {/* @ts-expect-error TS(2322) FIXME: Type '(event: any) => void' is not assignable to t... Remove this comment to see the full error message */}
      <DateButton onClick={handleShowPopover} {...props}>
        Andet
      </DateButton>
      {/* @ts-expect-error TS(2322) FIXME: Type 'undefined' is not assignable to type 'HTMLEl... Remove this comment to see the full error message */}
      <CustomDateRangePopover target={target} show={showPopover} onClose={handleClosePopover} />
    </>
  )
}

export default CustomDateRangeButton
