import Text from '@nord/ui/src/components/Text'
import React from 'react'
import Col from 'react-bootstrap/Col'

import { useAllocationContext } from './AllocationContext'
import Chart from './Chart'

const ChartWrapper = () => {
  // @ts-expect-error TS(2339) FIXME: Property 'chartData' does not exist on type 'unkno... Remove this comment to see the full error message
  const { chartData } = useAllocationContext()

  return (
    <Col xs={12} lg={{ span: 5, offset: 1 }} className="my-lg-auto">
      {chartData.length === 1 ? (
        <Text as="p">Der er ingen kontanter eller investeringer på kontoen.</Text>
      ) : (
        <Chart />
      )}
    </Col>
  )
}

export default ChartWrapper
