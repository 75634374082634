import LoginForm from '@nord/ui/src/components/LoginForm'
import React from 'react'

import FormPageWrapper from '../FormPageWrapper'

const LoginPage = () => (
  <FormPageWrapper>
    <LoginForm />
  </FormPageWrapper>
)

export default LoginPage
