import Text from '@nord/ui/src/components/Text'
import { getConfig } from '@nord/ui/src/configuration'
import useObjectSelector from '@nord/ui/src/hooks/useObjectSelector'
import { selectCurrentPortfolio } from '@nord/ui/src/store/current/portfolioId'
import formatDate from '@nord/ui/src/utilities/formatDate'
import React from 'react'
import Button from 'react-bootstrap/Button'

import CenteredTextSection from '../../../../components/CenteredTextSection'
import useIsPayoutAccount from '../../../../hooks/useIsPayoutAccount'

const getSuitabilityFlowStartUrl = getConfig('dynamicUrls.suitabilityFlow.start')

const SuitabilityContent = () => {
  const {
    // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'unknown'.
    id: portfolioId,
    // @ts-expect-error TS(2339) FIXME: Property 'onboardingFlow' does not exist on type '... Remove this comment to see the full error message
    onboardingFlow,
    // @ts-expect-error TS(2339) FIXME: Property 'suitabilityTestSucceededAt' does not exi... Remove this comment to see the full error message
    suitabilityTestSucceededAt,
    // @ts-expect-error TS(2339) FIXME: Property 'suitabilityState' does not exist on type... Remove this comment to see the full error message
    suitabilityState,
  } = useObjectSelector(selectCurrentPortfolio)

  const isPension = onboardingFlow === 'pension'

  const isPayoutAccount = useIsPayoutAccount()

  const suitabilityFlowStartUrl = getSuitabilityFlowStartUrl(portfolioId)

  const lastUpdatedDate = suitabilityTestSucceededAt && formatDate(suitabilityTestSucceededAt)

  const isReadyForRebalancing = suitabilityState === 'ready_for_rebalancing_test'

  if (isPension) return null
  if (isPayoutAccount) return null

  return (
    <>
      <hr />
      <CenteredTextSection className="py-4">
        {isReadyForRebalancing ? (
          <>
            <Text as="h4" align="center" className="my-3">
              Rebalancering
            </Text>
            <Text as="p" className="mb-0">
              Din portefølje har ændret risiko grundet ændringer på aktie- og obligationsmarkederne.
              Det betyder, at din risikoprofil ikke er den samme som dit udgangspunkt. Derfor
              anbefaler vi, at din portefølje bliver rebalanceret til dit udgangspunkt, så din
              risikoprofil dermed fastholdes. Du kan lære mere om rebalancering på linket nedenfor,
              eller i den email vi har sendt dig. Vi gør ikke noget før du har opdateret dine
              oplysninger og godkendt en rebalancering.
            </Text>
            <Button as="a" variant="link" href={suitabilityFlowStartUrl}>
              Gå til rebalancering
            </Button>
          </>
        ) : (
          <>
            <Text as="h4" align="center" className="my-3">
              Opdatering af risikovurdering
            </Text>
            <Text as="p">
              Din risikovurdering er sidst blevet opdateret d. {lastUpdatedDate}. Er der sket
              væsentlige ændringer i din økonomi, tidshorisont, eller risikovillighed siden da, så
              anbefaler vi at du tager en ny test af din risikoprofil for at sikre, at dine
              investeringer er egnet for dig. Norm Invest vil også løbende (min. hver 18. måned)
              bede dig om at opdatere dine oplysninger.
            </Text>
            <Text as="div">Sidst opdateret d. {lastUpdatedDate}.</Text>
            <Button as="a" variant="link" href={suitabilityFlowStartUrl}>
              Opdater din risikovurdering
            </Button>
          </>
        )}
      </CenteredTextSection>
    </>
  )
}

export default SuitabilityContent
