import React from 'react'

import FormPageWrapper from '../FormPageWrapper'

import CreatePasswordForm from './CreatePasswordForm'

const CreatePasswordPage = () => (
  <FormPageWrapper>
    <CreatePasswordForm />
  </FormPageWrapper>
)

export default CreatePasswordPage
