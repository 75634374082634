import styleConfiguration from '@nord/ui/src/configuration/styleConfiguration'
import formatDate from '@nord/ui/src/utilities/formatDate'
import { numberToCurrency, numberToPercent } from '@nord/ui/src/utilities/numberFormatter'
import classNames from 'classnames'
import max from 'lodash/max'
import min from 'lodash/min'
import React from 'react'
import Card from 'react-bootstrap/Card'
import { withResizeDetector } from 'react-resize-detector/build/withPolyfill'
import { XAxis, YAxis, Tooltip, Line, LineChart } from 'recharts'

import styles from './index.module.scss'

const { blues } = styleConfiguration

export interface Props {
  data: {
    timestamp?: number
    value?: number
  }[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  chartType: any // TODO: PropTypes.oneOf(VALID_CHART_TYPES)
  height?: number
  width?: number
}

const ChartContainer = ({ data, chartType, width, height }: Props) => {
  const timeWeighted = chartType === 'timeWeighted'
  const historicalValues = chartType === 'values'
  const ticks = [data[0].timestamp, data[data.length - 1].timestamp]

  const dataValues = data.map((dataEntry) => dataEntry.value)
  const maxValue = max(dataValues)
  const minValue = min(dataValues)
  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  const dataValueDiff = maxValue - minValue
  const yAxisBuffer = historicalValues || timeWeighted ? dataValueDiff / 3.0 : 0
  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  const baseValue = minValue - yAxisBuffer

  return (
    <div className="flex-fill w-100">
      <LineChart
        data={data}
        // @ts-expect-error TS(2322) FIXME: Type '{ children: Element[]; data: { timestamp?: n... Remove this comment to see the full error message
        baseValue={baseValue}
        width={width}
        height={height}
        margin={{
          top: 20,
          right: 0,
          bottom: 0,
          left: 0,
        }}
      >
        <defs>
          <linearGradient id="gradientFill" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={blues[300]} stopOpacity={1} />
            <stop offset="95%" stopColor={blues[300]} stopOpacity={1} />
          </linearGradient>
        </defs>
        <XAxis
          dataKey="timestamp"
          // @ts-expect-error TS(2322) FIXME: Type '(number | undefined)[]' is not assignable to... Remove this comment to see the full error message
          ticks={ticks}
          tickFormatter={(timestamp) => formatDate(timestamp)}
          tickLine={false}
          axisLine={false}
          interval={0}
          mirror
          allowDataOverflow
        />
        {/* @ts-expect-error TS(2322) FIXME: Type '{ type: "number"; domain: [number, string]; ... Remove this comment to see the full error message */}
        <YAxis type="number" domain={[baseValue, 'dataMax']} datakey="value" hide />
        {/* <Area dataKey="value" stroke="none" fill="url(#gradientFill)" fillOpacity={1} /> */}
        <Line dataKey="value" stroke="#000000" fill="url(#gradientFill)" fillOpacity={1} dot={false} />
        <Tooltip
          cursor={false}
          // eslint-disable-next-line react/no-unstable-nested-components
          content={({ payload }) => {
            if (!payload) return null
            if (!payload.length) return null
            if (!payload[0].payload) return null

            return (
              <Card className={styles.tooltip}>
                <Card.Body className={styles.tooltipBody}>
                  <Card.Title className={classNames(styles.tooltipTitle, 'font-size-md')}>
                    {timeWeighted
                      ? numberToPercent(payload[0].value, {
                          maximumFractionDigits: 2,
                        })
                      : numberToCurrency(payload[0].value)}
                  </Card.Title>
                  <Card.Text className="font-size-sm">
                    {formatDate(payload[0].payload.timestamp)}
                  </Card.Text>
                </Card.Body>
              </Card>
            )
          }}
        />
      </LineChart>
    </div>
  )
}

ChartContainer.defaultProps = {
  height: undefined,
  width: undefined,
}

export default withResizeDetector(ChartContainer)
