import NavMenu from '@nord/ui/src/components/NavMenu'
import useObjectSelector from '@nord/ui/src/hooks/useObjectSelector'
import { selectCurrentPortfolio } from '@nord/ui/src/store/current/portfolioId'
import React, { useState } from 'react'

import DateSelection from '../../pages/AuthPages/ChartPage/DateSelection'

import useNavigationItems from './useNavigationItems'

const Header = () => {
  // @ts-expect-error TS(2339) FIXME: Property 'appState' does not exist on type 'unknow... Remove this comment to see the full error message
  const { appState, createdInSaxoAt } = useObjectSelector(selectCurrentPortfolio)
  const [showMobileNav, setShowMobileNav] = useState(false)

  const handleToggleMobileNav = (newShow: any) => setShowMobileNav(newShow)
  const isInvested = appState === 'invested' || appState === 'deposit_recieved'
  const isCreatedInSaxo = !!createdInSaxoAt

  const {
    left: leftNav,
    right: rightNav,
    footer: footerNav,
  } = useNavigationItems({
    isInvested,
    isCreatedInSaxo,
    onToggleMobileNav: handleToggleMobileNav,
  })

  return (
    <NavMenu
      showMobileNav={showMobileNav}
      onToggleMobileNav={handleToggleMobileNav}
      leftNav={leftNav}
      rightNav={rightNav}
      footer={
        isInvested && (
          <>
            <div className="d-flex flex-row justify-content-center d-lg-none mb-1">{footerNav}</div>
            <DateSelection />
          </>
        )
      }
      fluid
    />
  )
}

export default Header
