import type { ChartTypeEndpointKeys } from '@nord/api'
import { createContext, useContext } from 'react'

export interface AppContextType {
  chartType: ChartTypeEndpointKeys
  dateFrom?: string
  dateTo?: string
  onChangeDate(fromDate?: string, toDate?: string): void
}

const AppContext = createContext<AppContextType | null>(null)

export const useAppContext = () => {
  const context = useContext(AppContext)

  if (!context) {
    throw new Error('useAppContext must be used within an AppProvider')
  }

  return context
}

export default AppContext
