import Card from '@nord/ui/src/components/Card'
import Text from '@nord/ui/src/components/Text'
import React from 'react'

export interface Props {
  children?: React.ReactNode
  message?: string | React.ReactNode
  title: string
}

const MessageCard = ({ title, message, children, ...otherProps }: Props) => (
  <Card {...otherProps}>
    <Card.Body>
      {/* @ts-expect-error TS(2322) FIXME: Type '{ children: string; as: "h2"; align: string;... Remove this comment to see the full error message */}
      <Card.Title as="h2" align="center" className="mb-3">
        {title}
      </Card.Title>
      <Text as="p" align="center">
        {message}
      </Text>
      {children}
    </Card.Body>
  </Card>
)

MessageCard.defaultProps = {
  children: undefined,
  message: undefined,
}

export default MessageCard
