import { formatISO, parseISO } from 'date-fns'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'

import DateInput from './DateInput'

const isEmptyString = (string: any) => {
  if (string === '') return true

  return false
}

const formatDate = (date: any) =>
  formatISO(date, {
    representation: 'date',
  })

const formatInputDate = (date: any) => formatDate(parseISO(date))

export interface DateRangeInputProps {
  value: {
    from?: string
    to?: string
  }
  min: string
  max: string
  onValueChange: (...args: any[]) => any
}

const DateRangeInput = ({ value, min, max, onValueChange, ...otherProps }: DateRangeInputProps) => {
  const { from: fromDate, to: toDate } = value

  const minFromDate = !isEmptyString(min) && formatInputDate(min)
  const maxFromDate = !isEmptyString(toDate) && formatInputDate(toDate)
  const minToDate = !isEmptyString(fromDate) && formatInputDate(fromDate)
  const maxToDate = formatInputDate(max)

  const handleFromChange = (dateFrom: any) => onValueChange({ from: dateFrom })
  const handleToChange = (dateTo: any) => onValueChange({ to: dateTo })

  return (
    <>
      <Form.Group as={Col}>
        <Form.Label>Fra</Form.Label>
        <DateInput
          // @ts-expect-error TS(2322) FIXME: Type '{ name: string; value: string | undefined; m... Remove this comment to see the full error message
          name="from"
          value={fromDate}
          min={minFromDate}
          max={maxFromDate}
          onValueChange={handleFromChange}
          {...otherProps}
        />
      </Form.Group>
      <Form.Group as={Col}>
        <Form.Label>Til</Form.Label>
        <DateInput
          // @ts-expect-error TS(2322) FIXME: Type '{ name: string; value: string | undefined; m... Remove this comment to see the full error message
          name="to"
          value={toDate}
          min={minToDate}
          max={maxToDate}
          onValueChange={handleToChange}
          {...otherProps}
        />
      </Form.Group>
    </>
  )
}

export default DateRangeInput
