import { selectCurrentPortfolioId } from '@nord/ui/src/store/current/portfolioId'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { useHistory } from 'react-router-dom'

import { useSustainabilityContext } from '../Provider'

const useSustainabilityRedirect = () => {
  // @ts-expect-error TS(2339) FIXME: Property 'answerData' does not exist on type 'unkn... Remove this comment to see the full error message
  const { answerData } = useSustainabilityContext()
  const history = useHistory()
  const portfolioId = useSelector(selectCurrentPortfolioId)

  useEffect(() => {
    if (answerData) return

    history.push(`/portefoeljer/${portfolioId}/anbefaling`)
  }, [answerData, history, portfolioId])
}

export default useSustainabilityRedirect
