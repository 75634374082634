import type { GetPortfolioChartParams } from '@nord/api'
import { getPortfolioChartLive } from '@nord/api'
import { useQueries } from '@tanstack/react-query'

interface Options {
  enabled?: boolean
  refetchInterval?: number
}

export const usePortfolioChartLiveQueries = <T extends GetPortfolioChartParams>(
  portfolios: T[],
  { refetchInterval, enabled = true }: Options = {},
) => {
  const queries = useQueries({
    queries: portfolios.map((portfolio) => ({
      queryKey: [getPortfolioChartLive.name, portfolio.portfolioId],
      queryFn: () => getPortfolioChartLive(portfolio),
      select: (data: Awaited<ReturnType<typeof getPortfolioChartLive>>) => ({
        ...portfolio,
        ...data,
      }),
      enabled,
      refetchInterval,
      staleTime: 60_000,
    })),
  })

  return queries
}

export default {
  usePortfolioChartLiveQueries,
}
