import FullPageTransition from '@nord/ui/src/components/FullPageTransition'
import FullScreenSection from '@nord/ui/src/components/FullScreenSection'
import NordHeader from '@nord/ui/src/components/Header'
import PageSwitchTransition from '@nord/ui/src/components/PageSwitchTransition'
import React from 'react'
import Navbar from 'react-bootstrap/Navbar'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { Switch, Route, Redirect, useLocation } from 'react-router-dom'

import CreatePasswordPage from './CreatePasswordPage'
import ForgotPasswordPage from './ForgotPasswordPage'
import LoginPage from './LoginPage'
import ResetPasswordPage from './ResetPasswordPage'

const NotAuthPages = () => {
  const location = useLocation()
  const { pathname: currentPath } = location

  return (
    <FullScreenSection topSection>
      <NordHeader mobile>
        <Navbar role="banner">
          <NordHeader.Logo />
        </Navbar>
      </NordHeader>
      <Switch location={location}>
        <Route path="/brugere/logind" component={LoginPage} exact />
        <Route path="/brugere/adgangskode/glemt" component={ForgotPasswordPage} exact />
        <Route path="/brugere/adgangskode/nulstil" component={ResetPasswordPage} exact />
        <Route path="/brugere/adgangskode/opret" component={CreatePasswordPage} exact />
        <Redirect
          from="*"
          to={{
            pathname: '/brugere/logind',
            search: currentPath !== '/' && `?redirect_to=${currentPath}`,
          }}
        />
      </Switch>
    </FullScreenSection>
  )
}

export default NotAuthPages
