import IconButton from '@nord/ui/src/components/IconButton'
import TimeoutProgressBar from '@nord/ui/src/components/TimeoutProgressBar'
import useApi from '@nord/ui/src/hooks/useApi'
import useInterval from '@nord/ui/src/hooks/useInterval'
import { numberToCurrency } from '@nord/ui/src/utilities/numberFormatter'
import React, { useCallback, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import Download from 'react-feather/dist/icons/download'

const DEFAULT_TIMEOUT_LIMIT = 30000

export interface Props {
  amount: number
  name: string
  number: number
  onTimeoutError: (...args: any[]) => any
}

const Invoice = ({ amount, name, number, onTimeoutError }: Props) => {
  const [loading, setLoading] = useState(false)
  const [downloadable, setDownloadable] = useState(false)
  const [invoiceUrl, setInvoiceUrl] = useState()

  const timeoutCallback = useCallback(() => {
    onTimeoutError(true)
    setLoading(false)
  }, [onTimeoutError])

  const downloadSingleInvoice = useApi(`/invoices/${number}/download`, {
    method: 'POST',
    withCredentials: true,
  })

  const fetchSingleInvoice = useApi(`/invoices/${number}`, {
    method: 'GET',
    withCredentials: true,
  })

  const handleResponse = (success: any, downloaded: any, cloudFile: any) => {
    if (!success) {
      setLoading(false)

      return
    }

    if (!downloaded) return

    const { url } = cloudFile

    setLoading(false)
    setDownloadable(true)
    setInvoiceUrl(url)
  }

  const handleDownload = async () => {
    setLoading(true)
    onTimeoutError(false)
    const {
      data: { downloaded, cloudFile },
      success,
      // @ts-expect-error TS(2554) FIXME: Expected 1-2 arguments, but got 0.
    } = await downloadSingleInvoice()

    handleResponse(success, downloaded, cloudFile)
  }

  const handleCallSingleInvoice = useCallback(async () => {
    const {
      data: { downloaded, cloudFile },
      success,
      // @ts-expect-error TS(2554) FIXME: Expected 1-2 arguments, but got 0.
    } = await fetchSingleInvoice()

    handleResponse(success, downloaded, cloudFile)
  }, [fetchSingleInvoice])

  useInterval(handleCallSingleInvoice, {
    duration: 5000,
    enable: loading,
    timeout: {
      limit: DEFAULT_TIMEOUT_LIMIT,
      callback: timeoutCallback,
    },
  })

  return (
    <>
      <Col xs={9} lg={4} className="text-left">
        {name}
      </Col>
      <Col xs={3} lg={4} className="text-right text-lg-center">
        Beløb: {numberToCurrency(amount)}
      </Col>
      <Col className="text-left text-lg-right mt-2 mt-lg-0">
        {downloadable ? (
          <IconButton
            variant="link"
            href={invoiceUrl}
            target="_blank"
            className="py-0 px-0"
            icon={Download}
          >
            Hent faktura
          </IconButton>
        ) : (
          <Button
            variant="link"
            className="py-0 px-0"
            onClick={() => handleDownload()}
            disabled={loading}
          >
            Generer faktura
          </Button>
        )}
        {loading && (
          <div className="d-inline-block w-50 ml-5">
            <TimeoutProgressBar timeoutLimit={DEFAULT_TIMEOUT_LIMIT} />
          </div>
        )}
      </Col>
    </>
  )
}

export default Invoice
