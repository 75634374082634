import Icon from '@nord/ui/src/components/Icon'
import LogOutButton from '@nord/ui/src/components/LogOutButton'
import NavMenu from '@nord/ui/src/components/NavMenu'
import useBreakpointHandler from '@nord/ui/src/hooks/useBreakpointHandler'
import useObjectSelector from '@nord/ui/src/hooks/useObjectSelector'
import { selectCurrentPortfolio } from '@nord/ui/src/store/current/portfolioId'
import { selectPortfolios } from '@nord/ui/src/store/current/portfolios'
import { selectCurrentUser } from '@nord/ui/src/store/current/user'
import classNames from 'classnames'
import React from 'react'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import ChevronDown from 'react-feather/dist/icons/chevron-down'
import { useSelector } from 'react-redux'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { NavLink } from 'react-router-dom'

import { useAppContext } from '../../context/AppContext'
import useIsPayoutAccount from '../../hooks/useIsPayoutAccount'

import ExpandableMobileMenu from './ExpandableMobileMenu'
import styles from './index.module.scss'
import MyAccountMenuItem from './MyAccountMenuItem'

const chartTypeLabels = {
  values: 'Overblik',
  timeWeighted: 'Afkast',
}

const chartTypePaths = {
  values: 'historisk',
  timeWeighted: 'tidsvaegtet',
}

const useNavigationItems = ({
  onToggleMobileNav,
  isInvested,
  isCreatedInSaxo,
}: {
  isCreatedInSaxo: boolean
  isInvested: boolean
  onToggleMobileNav(value: boolean): void
}) => {
  const { dateFrom, dateTo } = useAppContext()
  const largeAndUp = useBreakpointHandler('lg')
  const { appState: userAppState } = useSelector(selectCurrentUser)
  const portfoliosData = useSelector(selectPortfolios)
  // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'unknown'.
  const { id: portfolioId } = useObjectSelector(selectCurrentPortfolio)
  const isPensionPayoutAccount = useIsPayoutAccount()

  let dateParam: string
  if (dateFrom) dateParam = `?from=${dateFrom}`
  if (dateFrom && dateTo) dateParam = `?from=${dateFrom}&to=${dateTo}`

  const showChangePortfolioOption = Object.keys(portfoliosData).length > 1

  const navigationItems = {
    left: [] as JSX.Element[],
    right: [] as JSX.Element[],
    footer: [] as JSX.Element[],
  }

  let mainNavItems: JSX.Element[] = []
  if (isInvested) {
    mainNavItems = Object.entries(chartTypeLabels).map(([type, label]) => (
      // @ts-expect-error TS(2322) FIXME: Type '{ children: Element; key: string; }' is not ... Remove this comment to see the full error message
      <NavMenu.Item key={type}>
        {/* @ts-expect-error TS(2322) FIXME: Type '{ children: string; className: string | unde... Remove this comment to see the full error message */}
        <NavMenu.Link
          className={largeAndUp ? undefined : styles.mobileChartTypeItem}
          as={NavLink}
          activeClassName="text-primary"
          to={{
            // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            pathname: `/portefoeljer/${portfolioId}/${chartTypePaths[type]}`,
            search: dateParam,
          }}
          animated
        >
          {label}
        </NavMenu.Link>
      </NavMenu.Item>
    ))
  }

  if (isCreatedInSaxo && !isPensionPayoutAccount)
    mainNavItems.push(
      <NavMenu.Item>
        {/* @ts-expect-error TS(2322) FIXME: Type '{ children: string; className: string | unde... Remove this comment to see the full error message */}
        <NavMenu.Link
          className={largeAndUp ? undefined : styles.mobileChartTypeItem}
          as={NavLink}
          to={`/portefoeljer/${portfolioId}/ny_indbetaling`}
          activeClassName=""
          animated
        >
          Ny indbetaling
        </NavMenu.Link>
      </NavMenu.Item>,
    )

  if (largeAndUp) navigationItems.left = mainNavItems
  else navigationItems.footer = mainNavItems

  if (largeAndUp && portfolioId && userAppState !== 'user_created')
    navigationItems.left.push(
      <NavMenu.Item>
        {/* @ts-expect-error TS(2322) FIXME: Type '{ children: string; as: any; to: string; act... Remove this comment to see the full error message */}
        <NavMenu.Link
          as={NavLink}
          to={`/portefoeljer/${portfolioId}`}
          activeClassName="text-deep-dark"
          isActive={(match: boolean, location: typeof window.location) => {
            if (!match) return false
            if (Object.values(chartTypePaths).includes(location.pathname.split('/').slice(-1)[0]))
              return false

            if (
              ['information', 'ny_indbetaling'].includes(location.pathname.split('/').slice(-1)[0])
            )
              return false

            return true
          }}
          animated
        >
          Mere
        </NavMenu.Link>
      </NavMenu.Item>,
    )

  navigationItems.right = [
    // @ts-expect-error TS(2322) FIXME: Type '{ children: Element; className: string; }' i... Remove this comment to see the full error message
    <NavMenu.Item
      className={classNames({
        'd-none': !showChangePortfolioOption,
      })}
    >
      {/* @ts-expect-error TS(2322) FIXME: Type '{ children: (string | Element)[]; as: any; t... Remove this comment to see the full error message */}
      <NavMenu.Link
        as={NavLink}
        to="/portefoeljer"
        activeClassName="text-deep-dark"
        onClick={() => onToggleMobileNav(false)}
        exact
        animated
      >
        Skift portefølje
        <Icon icon={ChevronDown} className="d-none d-lg-inline-block" />
      </NavMenu.Link>
    </NavMenu.Item>,
    <ExpandableMobileMenu />,
    <MyAccountMenuItem />,
    <NavMenu.Item>
      {/* @ts-expect-error TS(2739) FIXME: Type '{ ({ className, animated, as, ...otherProps ... Remove this comment to see the full error message */}
      <LogOutButton as={NavMenu.Link} label="Log ud" animated />
    </NavMenu.Item>,
  ]

  return navigationItems
}

export default useNavigationItems
