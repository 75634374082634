import Icon from '@nord/ui/src/components/Icon'
import IconButton from '@nord/ui/src/components/IconButton'
import Text from '@nord/ui/src/components/Text'
import { formatDateTime } from '@nord/ui/src/utilities/formatDate'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import Download from 'react-feather/dist/icons/download'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import Loader from 'react-feather/dist/icons/loader'

export interface Props {
  documents?: {
    filename: string
    url: string
    folder: string
  }[]
  loading?: boolean
}

const List = ({ documents, loading }: Props) => {
  if (loading && !documents)
    return (
      <div className="text-center">
        <Icon icon={Loader} spin />
      </div>
    )

  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  if (documents.length === 0) {
    return (
      <Row className="py-3">
        <Col>
          <Text as="div" align="center">
            Du har endnu ingen rapporter. Du kan genererer en rapport nedenfor.
          </Text>
        </Col>
      </Row>
    )
  }

  return (
    <ul className="list-unstyled">
      <Row as="li" className="border-bottom">
        <Col xs={7} md={6}>
          <Text as="div" align="left" transform="uppercase" variant="secondary">
            Titel
          </Text>
        </Col>
        <Col md={3} className="d-none d-md-block">
          <Text as="div" align="left" transform="uppercase" variant="secondary">
            Oprettet
          </Text>
        </Col>
        <Col xs={5} md={3} />
      </Row>
      {/* @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'. */}
      {documents.map(({ filename, url, lastModifiedAt }) => (
        <Row key={url} as="li" className="border-bottom py-3">
          <Col xs={7} md={6} className="overflow-hidden text-truncate">
            <Text
              as="a"
              href={url}
              target="_blank"
              rel="noopener noreferrer"
              align="left"
              className="mb-0"
            >
              {filename}
            </Text>
          </Col>
          <Col md={3} className="d-none d-md-block">
            <Text>{formatDateTime(lastModifiedAt)}</Text>
          </Col>
          <Col xs={5} md={3}>
            <IconButton
              as="a"
              size="sm"
              variant="link"
              href={url}
              icon={Download}
              target="_blank"
              rel="noopener noreferrer"
              download
            >
              Hent og gem rapport
            </IconButton>
          </Col>
        </Row>
      ))}
    </ul>
  )
}

List.displayName = 'DocumentsForm.List'

List.defaultProps = {
  documents: undefined,
  loading: undefined,
}

export default List
