import Text from '@nord/ui/src/components/Text'
import { getConfig } from '@nord/ui/src/configuration'
import { numberToCurrency } from '@nord/ui/src/utilities/numberFormatter'
import React from 'react'
import Modal from 'react-bootstrap/Modal'

import styles from './Modal.module.scss'

const memberReferralAmount = getConfig('numbers.memberReferralAmount')

export interface Props {
  onHide: (...args: any[]) => any
  show: boolean
}

const ReferralTermsModal = ({ onHide, show }: Props) => (
  <Modal
    className="text-dark"
    onHide={onHide}
    show={show}
    size="lg"
    backdropClassName={styles.backdropBackground}
    centered
  >
    <Modal.Header closeButton>
      <Modal.Title className="w-100 text-center" data-cy="modal-title">
        Betingelser
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Text as="p">
        Hvis din ven investerer minimum {numberToCurrency(50000)} i 1 måned med henvisning fra dig,
        vil vedkommende modtage en belønning på {numberToCurrency(memberReferralAmount)} fra Norm Invest
        på sin investeringskonto, forudsat at følgende betingelser er opfyldt:
      </Text>
      <ul>
        <li>
          Din ven må ikke allerede være oprettet hos Norm Invest i dag eller have været kunde
          hos Norm Invest inden for de seneste 12 måneder.
        </li>
        <li>
          Din ven skal kunne godkendes af Norm Invest som kunde og erklæres egnet til at
          investere samt have oprettet konto og depot hos Saxo Bank.
        </li>
      </ul>
      <Text as="p">
        Når betingelserne er opfyldt, vil din ven modtage{' '}
        {numberToCurrency(memberReferralAmount)}
      </Text>
      <Text as="p">
        De {numberToCurrency(memberReferralAmount)} vil blive indsat på din vens
        investeringskonto i kalendermåneden efter, at din ven har været investeret i 1 måned. Din
        ven kan altid trække sine penge ud, men vil ikke få de{' '}
        {numberToCurrency(memberReferralAmount)}, medmindre vedkommende ikke har haft {' '}
        {numberToCurrency(50000)} investeret i 1 sammenhængende måned.
      </Text>
      <Text as="p">Vi forbeholder os retten til at tilbagekalde tilbuddet om belønning.</Text>
    </Modal.Body>
  </Modal>
)

export default ReferralTermsModal
