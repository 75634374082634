import { getAdvisedAllocation } from '@nord/api'
import Portfolio from '@nord/ui/src/components/PortfolioTable'
import Text from '@nord/ui/src/components/Text'
import useQueryParams from '@nord/ui/src/hooks/useQuery'
import { selectCurrentPortfolio } from '@nord/ui/src/store/current/portfolioId'
import { useQuery } from '@tanstack/react-query'
import React, { useEffect } from 'react'
import Alert from 'react-bootstrap/Alert'
import { useSelector } from 'react-redux'

import { useSustainabilityContext } from '../Provider'

import Sustainability from './Sustainability'

const IntroPage = () => {
  // @ts-expect-error TS(2339) FIXME: Property 'cleanupContext' does not exist on type '... Remove this comment to see the full error message
  const { cleanupContext } = useSustainabilityContext()
  const successQueryParam = useQueryParams('success')
  const errorOccured = successQueryParam === 'false'
  const portfolio = useSelector(selectCurrentPortfolio)

  useEffect(() => {
    cleanupContext()
  }, [cleanupContext])

  const advisedAllocationQuery = useQuery(
    getAdvisedAllocation.query({ params: { portfolioId: portfolio.id } }),
  )

  const heading = [
    'Anbefalet risikoprofil',
    portfolio.advisedRiskScore,
    portfolio.advisedEsg ? '(ansvarlig)' : '',
  ]
    .join(' ')
    .trim()

  const subtext = [
    'Du har valgt risikoprofil',
    portfolio.riskScore,
    portfolio.esg ? '(ansvarlig)' : '',
  ]
    .join(' ')
    .trim()

  const hasAdvisedRiskScore = portfolio.riskScore === portfolio.advisedRiskScore
  const hasAdvisedEsg = portfolio.esg === portfolio.advisedEsg

  return (
    <>
      <Text as="h4" align="center" className="my-3">
        {heading}
      </Text>
      {!hasAdvisedRiskScore || !hasAdvisedEsg ? (
        <Text as="p" align="center">
          {subtext}.
        </Text>
      ) : null}
      <Portfolio data={advisedAllocationQuery.data ?? []} />
      {errorOccured && (
        <Alert variant="danger">
          <h6>Vi beklager, der er sket en fejl... </h6>
          Vi har rapporteret fejlen, og vil forsøge at udbedre den hurtigst muligt. Du kan
          genindlæse siden for at prøve igen. Oplever du forsat fejl så kontakt os, vi sidder klar
          til at hjælpe!
        </Alert>
      )}
      <hr />
      <Text as="h5" align="center" className="my-3">
        Bæredygtighedspræferencer
      </Text>
      <Sustainability />
    </>
  )
}

export default IntroPage
