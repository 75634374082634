import useObjectSelector from '@nord/ui/src/hooks/useObjectSelector'
import { selectCurrentPortfolio } from '@nord/ui/src/store/current/portfolioId'

const useIsPayoutAccount = () => {
  // @ts-expect-error TS(2339) FIXME: Property 'depotType' does not exist on type 'unkno... Remove this comment to see the full error message
  const { depotType } = useObjectSelector(selectCurrentPortfolio)

  const isPayoutAccount = depotType === 'pensions_udbetaling'

  return isPayoutAccount
}

export default useIsPayoutAccount
