import React, { createContext, useContext, useMemo, useState } from 'react'

// @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 0.
export const Context = createContext()

export const useSustainabilityContext = () => useContext(Context)

export interface ProviderProps {
  children: React.ReactNode
}

const Provider = ({ children }: ProviderProps) => {
  const [questionsVersion, setQuestionsVersion] = useState()
  const [answerData, setAnswerData] = useState()
  const [preferencesOrder, setPreferencesOrder] = useState()

  const cleanupContext = () => {
    // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 0.
    setQuestionsVersion()
    // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 0.
    setAnswerData()
    // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 0.
    setPreferencesOrder()
  }

  const context = useMemo(
    () => ({
      questionsVersion,
      setQuestionsVersion,
      answerData,
      setAnswerData,
      preferencesOrder,
      setPreferencesOrder,
      cleanupContext,
    }),
    [answerData, preferencesOrder, questionsVersion],
  )

  return <Context.Provider value={context}>{children}</Context.Provider>
}

export default Provider
