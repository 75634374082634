import Icon from '@nord/ui/src/components/Icon'
import NavMenu from '@nord/ui/src/components/NavMenu'
import Transition from '@nord/ui/src/components/Transition'
import { selectCurrentPortfolioId } from '@nord/ui/src/store/current/portfolioId'
import { selectCurrentUser } from '@nord/ui/src/store/current/user'
import classNames from 'classnames'
import React, { useState } from 'react'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import ChevronDown from 'react-feather/dist/icons/chevron-down'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import ChevronUp from 'react-feather/dist/icons/chevron-up'
import { useSelector } from 'react-redux'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { Link } from 'react-router-dom'

import useIsPayoutAccount from '../../hooks/useIsPayoutAccount'

const ExpandableMobileMenu = () => {
  const { accountType, appState } = useSelector(selectCurrentUser)
  const portfolioId = useSelector(selectCurrentPortfolioId)
  const isPayoutAccount = useIsPayoutAccount()

  const isUserCreated = appState === 'user_created'
  const isCompany = accountType === 'company_account'

  const [showPortfolioSubMenu, setShowPortfolioSubMenu] = useState(false)
  const [showAccountSubMenu, setShowAccountSubMenu] = useState(false)

  const handleToggleShowPortfolioSubMenu = () => {
    if (showAccountSubMenu) setShowAccountSubMenu(false)
    setShowPortfolioSubMenu(!showPortfolioSubMenu)
  }

  const handleToggleShowAccountSubMenu = () => {
    if (showPortfolioSubMenu) setShowPortfolioSubMenu(false)
    setShowAccountSubMenu(!showAccountSubMenu)
  }

  return (
    <div className="d-lg-none">
      <NavMenu.Item>
        {/* @ts-expect-error TS(2322) FIXME: Type '{ children: (string | Element)[]; onClick: (... Remove this comment to see the full error message */}
        <NavMenu.Link
          to="#"
          onClick={handleToggleShowAccountSubMenu}
          className={classNames(
            {
              'text-white': showAccountSubMenu,
            },
            'text-center',
          )}
          data-cy="min-konto"
        >
          Min konto
          <Icon icon={showAccountSubMenu ? ChevronUp : ChevronDown} className="ml-1" />
        </NavMenu.Link>
      </NavMenu.Item>
      <Transition.Expand in={showAccountSubMenu} key="accountSubMenu">
        <NavMenu.Item>
          {/* @ts-expect-error TS(2322) FIXME: Type '{ children: string; as: any; className: stri... Remove this comment to see the full error message */}
          <NavMenu.Link as={Link} className="font-size-xs text-center" to="/konto">
            Mine informationer
          </NavMenu.Link>
        </NavMenu.Item>
        <NavMenu.Item>
          {/* @ts-expect-error TS(2322) FIXME: Type '{ children: string; as: any; className: stri... Remove this comment to see the full error message */}
          <NavMenu.Link as={Link} className="font-size-xs text-center" to="/konto/dokumenter">
            Dokumenter
          </NavMenu.Link>
        </NavMenu.Item>
        <NavMenu.Item>
          {/* @ts-expect-error TS(2322) FIXME: Type '{ children: string; as: any; className: stri... Remove this comment to see the full error message */}
          <NavMenu.Link as={Link} className="font-size-xs text-center" to="/konto/fakturaer">
            Fakturaer
          </NavMenu.Link>
        </NavMenu.Item>
        {(!isCompany || isUserCreated) && (
          <NavMenu.Item>
            {/* @ts-expect-error TS(2322) FIXME: Type '{ children: string; as: any; className: stri... Remove this comment to see the full error message */}
            <NavMenu.Link as={Link} className="font-size-xs text-center" to="/konto/oprettelser">
              Depotoprettelser
            </NavMenu.Link>
          </NavMenu.Item>
        )}
        <NavMenu.Item>
          {/* @ts-expect-error TS(2322) FIXME: Type '{ children: string; as: any; className: stri... Remove this comment to see the full error message */}
          <NavMenu.Link as={Link} className="font-size-xs text-center" to="/konto/henvisning">
            Henvisning
          </NavMenu.Link>
        </NavMenu.Item>
        <NavMenu.Item>
          {/* @ts-expect-error TS(2322) FIXME: Type '{ children: string; as: any; className: stri... Remove this comment to see the full error message */}
          <NavMenu.Link as={Link} className="font-size-xs text-center" to="/konto/adgangskode">
            Skift adgangskode
          </NavMenu.Link>
        </NavMenu.Item>
      </Transition.Expand>
      {appState !== 'user_created' && (
        <NavMenu.Item>
          {/* @ts-expect-error TS(2322) FIXME: Type '{ children: (string | Element)[]; onClick: (... Remove this comment to see the full error message */}
          <NavMenu.Link
            to="#"
            onClick={handleToggleShowPortfolioSubMenu}
            className={classNames(
              {
                'text-white': showPortfolioSubMenu,
              },
              'text-center',
            )}
            data-cy="mere"
          >
            Mere
            <Icon icon={showPortfolioSubMenu ? ChevronUp : ChevronDown} className="ml-1" />
          </NavMenu.Link>
        </NavMenu.Item>
      )}
      <Transition.Expand in={showPortfolioSubMenu} key="portfolioSubMenu">
        <NavMenu.Item>
          {/* @ts-expect-error TS(2322) FIXME: Type '{ children: string; as: any; className: stri... Remove this comment to see the full error message */}
          <NavMenu.Link
            as={Link}
            className="font-size-xs text-center"
            to={`/portefoeljer/${portfolioId}`}
          >
            Information
          </NavMenu.Link>
        </NavMenu.Item>
        {!isPayoutAccount && (
          <NavMenu.Item>
            {/* @ts-expect-error TS(2322) FIXME: Type '{ children: string; as: any; to: string; cla... Remove this comment to see the full error message */}
            <NavMenu.Link
              as={Link}
              to={`/portefoeljer/${portfolioId}/ny_indbetaling`}
              className="font-size-xs text-center"
            >
              Ny indbetaling
            </NavMenu.Link>
          </NavMenu.Item>
        )}
        <NavMenu.Item>
          {/* @ts-expect-error TS(2322) FIXME: Type '{ children: string; as: any; className: stri... Remove this comment to see the full error message */}
          <NavMenu.Link
            as={Link}
            className="font-size-xs text-center"
            to={`/portefoeljer/${portfolioId}/kontobevaegelser`}
          >
            Kontobevægelser
          </NavMenu.Link>
        </NavMenu.Item>
        {!isPayoutAccount && (
          <>
            <NavMenu.Item>
              {/* @ts-expect-error TS(2322) FIXME: Type '{ children: string; as: any; className: stri... Remove this comment to see the full error message */}
              <NavMenu.Link
                as={Link}
                className="font-size-xs text-center"
                to={`/portefoeljer/${portfolioId}/portefolje`}
              >
                Portefølje
              </NavMenu.Link>
            </NavMenu.Item>
            <NavMenu.Item>
              {/* @ts-expect-error TS(2322) FIXME: Type '{ children: string; as: any; className: stri... Remove this comment to see the full error message */}
              <NavMenu.Link
                as={Link}
                className="font-size-xs text-center"
                to={`/portefoeljer/${portfolioId}/rapporter`}
              >
                Rapporter
              </NavMenu.Link>
            </NavMenu.Item>
            <NavMenu.Item>
              {/* @ts-expect-error TS(2322) FIXME: Type '{ children: string; as: any; className: stri... Remove this comment to see the full error message */}
              <NavMenu.Link
                as={Link}
                className="font-size-xs text-center"
                to={`/portefoeljer/${portfolioId}/forhandsgodkendelse`}
              >
                Forhåndsgodkendelse
              </NavMenu.Link>
            </NavMenu.Item>
            <NavMenu.Item>
              {/* @ts-expect-error TS(2322) FIXME: Type '{ children: string; as: any; className: stri... Remove this comment to see the full error message */}
              <NavMenu.Link
                as={Link}
                className="font-size-xs text-center"
                to={`/portefoeljer/${portfolioId}/anbefaling`}
              >
                Anbefaling
              </NavMenu.Link>
            </NavMenu.Item>
          </>
        )}
      </Transition.Expand>
    </div>
  )
}

export default ExpandableMobileMenu
