import get from './get'
import { openApiFactory } from './openApiFactory'

export const CHART_TYPE_ENDPOINTS = {
  values: 'historical_values',
  returns: 'historical_returns',
  timeWeighted: 'time_weighted_returns',
} as const

export type ChartTypeEndpointKeys = keyof typeof CHART_TYPE_ENDPOINTS

export interface GetPortfolioChartParams {
  portfolioId: number
  chartType: keyof typeof CHART_TYPE_ENDPOINTS
  dateFrom?: string
  dateTo?: string
}

export type GetPortfolioChartResult = [string, number][]

export function getPortfolioChart(params: GetPortfolioChartParams) {
  const { portfolioId, chartType, dateFrom, dateTo } = params

  const url = `/portfolios/${portfolioId}/charts/${CHART_TYPE_ENDPOINTS[chartType]}`
  const searchParams = { newFormat: true, from: dateFrom, to: dateTo }

  return get<GetPortfolioChartResult>(url, searchParams)
}

export type GetPortfolioChartLiveParams = GetPortfolioChartParams

export type GetPortfolioChartLiveResult = { value: number; return: number }

export async function getPortfolioChartLive(params: GetPortfolioChartParams) {
  const { portfolioId, chartType, dateFrom, dateTo } = params

  const url = `/portfolios/${portfolioId}/charts/${CHART_TYPE_ENDPOINTS[chartType]}/live`
  const searchParams = { newFormat: true, from: dateFrom, to: dateTo }

  const { value, return: returnInDkk } = await get<GetPortfolioChartLiveResult>(url, searchParams)

  return { value, returnInDkk }
}

export const getAdvisedAllocation = openApiFactory(
  '/portfolios/{portfolio_id}/advised_allocation',
  'get',
)
