import Card from '@nord/ui/src/components/Card'
import IconButton from '@nord/ui/src/components/IconButton'
import Loader from '@nord/ui/src/components/Loader'
import Text from '@nord/ui/src/components/Text'
import { getConfig } from '@nord/ui/src/configuration'
import useApi, { useApiRequest } from '@nord/ui/src/hooks/useApi'
import isEmpty from 'lodash/isEmpty'
import React from 'react'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import ArrowRight from 'react-feather/dist/icons/arrow-right'

import CenteredTextSection from '../../../../components/CenteredTextSection'
import usePageLeaveWarning from '../../../../hooks/usePageLeaveWarning'

const contactInformation = getConfig('contactInformation')

export interface StartVerficationLinksProps {
  authorizeUrl?: string
  saxoUsers: {
    authorizeUrl?: string
    name?: string
    userId?: string
  }[]
}

const StartVerficationLinks = ({ saxoUsers }: StartVerficationLinksProps) => {
  if (isEmpty(saxoUsers))
    return (
      <Text as="p">
        Du skal have et aktivt login hos Saxo Bank for at kunne lave en kontant udbetaling. Kontakt
        os venligst for at komme videre, på{' '}
        <Text as="a" href={contactInformation.emailLink}>
          {contactInformation.email}
        </Text>
        .
      </Text>
    )

  if (saxoUsers.length === 1) {
    const { authorizeUrl } = saxoUsers[0]

    return (
      <IconButton className="mb-3" icon={ArrowRight} href={authorizeUrl}>
        Næste
      </IconButton>
    )
  }

  return saxoUsers.map(({ name, userId, authorizeUrl: url }) => (
    <Card key={userId} className="text-left my-4 shadow">
      <Card.Body className="py-3 d-flex flex-column flex-lg-row align-items-center">
        <div className="flex-grow-1">{name}</div>
        <div>
          <IconButton size="sm" icon={ArrowRight} href={url} className="stretched-link">
            Næste
          </IconButton>
        </div>
      </Card.Body>
    </Card>
  ))
}

StartVerficationLinks.defaultProps = {
  authorizeUrl: undefined,
}

const StartPage = () => {
  usePageLeaveWarning()

  const getSaxoAuthUrl = useApi('/saxo_oauth/new', {
    method: 'GET',
    withCredentials: true,
  })

  const { data, loading } = useApiRequest(getSaxoAuthUrl, {
    autoCall: true,
  })

  const { authorizeUrl, saxoUsers } = data || {}

  return (
    <CenteredTextSection>
      {!isEmpty(saxoUsers) && (
        <>
          <Text as="p">
            Her kan du igangsætte en kontantudbetaling fra din investeringskonto til din egen bank.
            Du vil først blive videresendt til Saxo Bank, hvor du skal logge ind med NemID eller
            MitID og godkende, at Norm Invest må lave en overførsel fra din investeringskonto hos
            Saxo Bank. Klik nedenfor for at komme igang.
          </Text>
          <Text as="p">
            Ønsker du at sælge ud af din investering og frigive kontanter, så kontakt os på{' '}
            <Text as="a" href={contactInformation.emailLink}>
              {contactInformation.email}
            </Text>
            .
          </Text>
        </>
      )}
      {loading ? (
        <Loader className="text-center font-size-xl" />
      ) : (
        // @ts-expect-error TS(2786) FIXME: 'StartVerficationLinks' cannot be used as a JSX co... Remove this comment to see the full error message
        <StartVerficationLinks authorizeUrl={authorizeUrl} saxoUsers={saxoUsers} />
      )}
    </CenteredTextSection>
  )
}

export default StartPage
