import { selectCurrentUser } from '@nord/ui/src/store/current/user'
import camelCase from 'lodash/camelCase'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { useSelector } from 'react-redux'

import MessageCard from '../../../components/MessageCard'

import useStateMessageData from './useStateMessageData'

const UserStatePage = () => {
  const { appState } = useSelector(selectCurrentUser)

  const formattedAppState = camelCase(appState)

  const {
    // @ts-expect-error TS(2537) FIXME: Type '{ userCreated: { title: string; message: Ele... Remove this comment to see the full error message
    [formattedAppState]: { title, message },
  } = useStateMessageData()

  return (
    <Row className="h-100 align-items-center justify-content-center">
      <Col xs={12} md={10} lg={8} xl={6}>
        <MessageCard title={title} message={message} />
      </Col>
    </Row>
  )
}

export default UserStatePage
