import React from 'react'

export interface Props {
  fill: string
  width: string
  height: string
}

const Dot = ({ width, height, fill }: Props) => (
  <svg viewBox="0 0 100 100" height={height} width={width}>
    <circle cx="50" cy="50" r="50" fill={fill} />
  </svg>
)

export default Dot
