import Text from '@nord/ui/src/components/Text'
import React from 'react'

import StartPage from './StartPage'
import WithdrawalPage from './WithdrawalPage'

export interface Props {
  children: React.ReactNode
}

const CashWithdrawal = ({ children }: Props) => (
  <>
    <Text as="h4" align="center" className="my-3">
      Kontantudbetaling
    </Text>
    {children}
  </>
)

CashWithdrawal.StartPage = StartPage
CashWithdrawal.WithdrawalPage = WithdrawalPage

export default CashWithdrawal
