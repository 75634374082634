import BaffleButton from '@nord/ui/src/components/BaffleButton'
import Loader from '@nord/ui/src/components/Loader'
import Text from '@nord/ui/src/components/Text'
import {
  setCurrentPortfolioId,
  selectCurrentPortfolioId,
} from '@nord/ui/src/store/current/portfolioId'
import { selectPortfolios } from '@nord/ui/src/store/current/portfolios'
import { numberToCurrency } from '@nord/ui/src/utilities/numberFormatter'
import { findKey } from 'lodash'
import isEmpty from 'lodash/isEmpty'
import round from 'lodash/round'
import sum from 'lodash/sum'
import React from 'react'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { useDispatch, useSelector } from 'react-redux'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { Link } from 'react-router-dom'

import ModalPage from '../../../components/ModalPage'
import { CHART_TYPE_PATHS } from '../../../configuration/constants'
import { useAppContext } from '../../../context/AppContext'
import { usePortfolioChartLiveQueries } from '../../../hooks/usePortfolioChartLiveQueries'

import ReturnText from './ReturnText'

const getTotalValueAndReturn = (data: { value: number; returnInDkk: number }[]) => {
  if (isEmpty(data)) return null

  const portfolioTotalValues = data.map(({ value }) => round(value))
  const portfolioReturns = data.map(({ returnInDkk }) => round(returnInDkk))

  return {
    totalValue: sum(portfolioTotalValues),
    totalReturn: sum(portfolioReturns),
  }
}

const PortfolioSelectionPage = () => {
  const portfoliosData = useSelector(selectPortfolios) as { id: number; title: string }[]
  const currentPortfolioId = useSelector(selectCurrentPortfolioId)

  const dispatch = useDispatch()
  const handleChangeCurrentPortfolioId = (portfolioId: number) =>
    dispatch(setCurrentPortfolioId(portfolioId))

  const { chartType } = useAppContext()

  const portfolioQueries = usePortfolioChartLiveQueries(
    portfoliosData.map(({ id, title }) => ({
      portfolioId: id,
      title,
      chartType: 'values',
    })),
  )

  const portfolios = portfolioQueries
    .map((query) => query.data)
    .filter((data): data is NonNullable<typeof data> => data != null)

  const isLoading = portfolioQueries.some((query) => query.isInitialLoading)

  const chartParam = findKey(CHART_TYPE_PATHS, (value) => value === chartType)

  const backUrl = `/portefoeljer/${currentPortfolioId}/${chartParam}`

  const { totalValue, totalReturn } = getTotalValueAndReturn(portfolios) ?? {
    totalValue: 0,
    totalReturn: 0,
  }

  return (
    <ModalPage
      // @ts-expect-error TS(2322) FIXME: Type '{ children: Element; title: string; backUrl:... Remove this comment to see the full error message
      title="Vælg porteføjle"
      backUrl={backUrl}
      header={
        <Text as="h3" className="ml-auto">
          Porteføljer
        </Text>
      }
      closeButton
    >
      {isLoading ? (
        <Loader className="text-center my-5 font-size-xl" />
      ) : (
        <>
          <div className="mx-lg-6 d-flex justify-content-center align-items-center">
            <Text as="h4" size="lg" className="mb-0">
              Totalværdi
            </Text>
            <Text as="div" variant="secondary" className="ml-1">
              {numberToCurrency(totalValue)}
            </Text>
          </div>
          <div className="mx-lg-6 d-flex justify-content-center align-items-center">
            <Text as="h6" className="mb-0 mr-1">
              Total afkast
            </Text>
            <ReturnText value={totalReturn} />
          </div>
          <Row className="my-4 justify-content-center">
            {portfolios.map(({ portfolioId, title, value, returnInDkk }) => (
              <Col xs={12} md={6} lg={4} className="my-2">
                <Card key={portfolioId} className="shadow h-100">
                  <Card.Body
                    onClick={() => handleChangeCurrentPortfolioId(portfolioId)}
                    className="d-flex flex-column align-items-center"
                  >
                    <Text as="h6" align="center">
                      {title}
                    </Text>
                    <div className="mt-auto">
                      <Text as="div" align="center" variant="secondary" className="mb-auto">
                        {numberToCurrency(value)}
                      </Text>
                      <ReturnText value={returnInDkk} />
                      <BaffleButton
                        as={Link}
                        to={`/portefoeljer/${portfolioId}/historisk`}
                        size="sm"
                        className="stretched-link mt-4"
                        data-cy={`portfolio-${portfolioId}-link`}
                      >
                        Vælg porteføjle
                      </BaffleButton>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </>
      )}
    </ModalPage>
  )
}

export default PortfolioSelectionPage
