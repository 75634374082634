import Loader from '@nord/ui/src/components/Loader'
import Text from '@nord/ui/src/components/Text'
import useApi, { useApiRequest } from '@nord/ui/src/hooks/useApi'
import isEmpty from 'lodash/isEmpty'
import React, { useState } from 'react'
import Alert from 'react-bootstrap/Alert'
import Row from 'react-bootstrap/Row'

import CenteredTextSection from '../../../../components/CenteredTextSection'

import Invoice from './Invoice'

const Invoices = () => {
  const [isTimeoutError, setIsTimeoutError] = useState(false)

  const handleTimeoutError = (value: any) => setIsTimeoutError(value)

  const fetchInvoices = useApi('/invoices', {
    method: 'GET',
    withCredentials: true,
  })

  const { data: invoicesData, loading: loadingInvoicesData } = useApiRequest(fetchInvoices, {
    autoCall: true,
  })

  return (
    <>
      <Text as="h4" align="center" className="my-3">
        Fakturaer
      </Text>
      {isTimeoutError && (
        <Alert variant="danger" className="text-center">
          Der skete en fejl ved genereringen af din faktura. Prøv venligst igen. Kontakt os gerne,
          hvis fejlen fortsætter.
        </Alert>
      )}
      {loadingInvoicesData ? (
        <Loader className="text-center font-size-xxl" />
      ) : (
        <ul className="list-unstyled px-2">
          {isEmpty(invoicesData) ? (
            <CenteredTextSection>Der er ingen fakturaer at vise.</CenteredTextSection>
          ) : (
            invoicesData.reverse().map((invoice: any) => (
              <Row key={invoice.number} as="li" className="border-bottom py-2">
                <Invoice {...invoice} onTimeoutError={handleTimeoutError} />
              </Row>
            ))
          )}
        </ul>
      )}
    </>
  )
}

export default Invoices
