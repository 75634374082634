import useBreakpointHandler from '@nord/ui/src/hooks/useBreakpointHandler'
import useDeepCompareCallback from '@nord/ui/src/hooks/useDeepCompareCallback'
import useIsSignedIn from '@nord/ui/src/hooks/useIsSignedIn'
import useQuery from '@nord/ui/src/hooks/useQuery'
import { selectInitialLoading } from '@nord/ui/src/store/current/loading'
import {
  setCurrentPortfolioId,
  selectCurrentPortfolioId,
} from '@nord/ui/src/store/current/portfolioId'
import { selectPortfolioIds } from '@nord/ui/src/store/current/portfolios'
import callIntercom from '@nord/ui/src/utilities/callIntercom'
import { findKey } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { Redirect, useHistory } from 'react-router-dom'

import { CHART_TYPE_PATHS } from '../configuration/constants'
import type { AppContextType } from '../context/AppContext'
import AppContext from '../context/AppContext'
import useChartType from '../hooks/useChartType'
import usePortfolioId from '../hooks/usePortfolioId'
import AuthPages from '../pages/AuthPages'
import LoadingPage from '../pages/LoadingPage'
import NotAuthPages from '../pages/NotAuthPages'

type ChartTypePaths = typeof CHART_TYPE_PATHS
type ChartTypePathsKeys = keyof ChartTypePaths

const defaultChartTypePath: ChartTypePathsKeys = 'historisk'
const defaultChartType: ChartTypePaths[ChartTypePathsKeys] = CHART_TYPE_PATHS[defaultChartTypePath]

const PagesWrapper = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const queryParams = useQuery()
  const chartTypePath = useChartType()
  const routeId = usePortfolioId()
  const isSignedIn = useIsSignedIn()
  const allowedDeviceSize = useBreakpointHandler('lg')

  const { from: queryFrom, to: queryTo, redirectTo } = queryParams

  const portfolioIds = useSelector(selectPortfolioIds)
  const currentPortfolioId = useSelector(selectCurrentPortfolioId)
  const isLoading = useSelector(selectInitialLoading)

  let initialChartType: ChartTypePaths[ChartTypePathsKeys] = defaultChartType
  if (chartTypePath) initialChartType = CHART_TYPE_PATHS[chartTypePath]

  const [chartType, setChartType] = useState(initialChartType)
  const [dateFrom, setDateFrom] = useState(queryFrom)
  const [dateTo, setDateTo] = useState(queryTo)

  const invalidRoute =
    isSignedIn &&
    ((routeId && portfolioIds && !portfolioIds.includes(routeId)) || chartType === undefined)

  useEffect(() => {
    if (currentPortfolioId) return
    if (!routeId) return

    dispatch(setCurrentPortfolioId(routeId))
  }, [dispatch, routeId, currentPortfolioId])

  useEffect(() => {
    if (!isSignedIn) return
    if (!routeId) return
    if (invalidRoute) return
    if (routeId === currentPortfolioId) return

    dispatch(setCurrentPortfolioId(routeId))
  }, [dispatch, routeId, currentPortfolioId, isSignedIn, invalidRoute])

  useEffect(() => {
    // @ts-expect-error TS(2554) FIXME: Expected 2 arguments, but got 1.
    callIntercom('show')
  }, [])

  useEffect(() => {
    // @ts-expect-error TS(2554) FIXME: Expected 2 arguments, but got 1.
    if (allowedDeviceSize) callIntercom('show')
    // @ts-expect-error TS(2554) FIXME: Expected 2 arguments, but got 1.
    else callIntercom('shutdown')
  }, [allowedDeviceSize])

  useEffect(() => {
    if (redirectTo && isSignedIn) history.push(redirectTo)
  }, [history, isSignedIn, redirectTo])

  useEffect(() => {
    if (chartTypePath) setChartType(CHART_TYPE_PATHS[chartTypePath])
  }, [chartTypePath])

  const handleChangeDate = useDeepCompareCallback(
    (fromDate: string, toDate: string) => {
      const chartPath = findKey(CHART_TYPE_PATHS, (value) => value === chartType)
      const newPath = {
        pathname: `/portefoeljer/${currentPortfolioId}/${chartPath}`,
      }

      if (fromDate === undefined && toDate === undefined) {
        setDateFrom(fromDate)
        setDateTo(toDate)
        history.push(newPath)
      } else if (fromDate !== undefined && toDate === undefined) {
        // @ts-expect-error TS(2339) FIXME: Property 'search' does not exist on type '{ pathna... Remove this comment to see the full error message
        newPath.search = `?from=${fromDate}`
        setDateFrom(fromDate)
        setDateTo(toDate)
        history.push(newPath)
      } else if (fromDate !== undefined && toDate !== undefined) {
        // @ts-expect-error TS(2339) FIXME: Property 'search' does not exist on type '{ pathna... Remove this comment to see the full error message
        newPath.search = `?from=${fromDate}&to=${toDate}`
        setDateFrom(fromDate)
        setDateTo(toDate)
        history.push(newPath)
      }
    },
    [history, chartType, currentPortfolioId],
  )

  const context: AppContextType = useMemo(
    () => ({
      dateFrom,
      dateTo,
      chartType,
      onChangeDate: handleChangeDate,
    }),
    [dateFrom, dateTo, chartType, handleChangeDate],
  )

  let content
  if (isLoading || invalidRoute) content = <LoadingPage />
  else if (isSignedIn) content = <AuthPages />
  else content = <NotAuthPages />

  if (invalidRoute && currentPortfolioId)
    return <Redirect to={`/portefoeljer/${currentPortfolioId}/${defaultChartTypePath}`} />

  return <AppContext.Provider value={context}>{content}</AppContext.Provider>
}

export default PagesWrapper
