// import PenneoSigningLink from '@nord/ui/src/components/PenneoSigningLink'
import Text from '@nord/ui/src/components/Text'
import useApi from '@nord/ui/src/hooks/useApi'
import useObjectSelector from '@nord/ui/src/hooks/useObjectSelector'
import useWaitForStateChange from '@nord/ui/src/hooks/useWaitForStateChange'
import { selectCurrentPortfolioId } from '@nord/ui/src/store/current/portfolioId'
import { selectCurrentUser } from '@nord/ui/src/store/current/user'
import { findKey } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { useHistory } from 'react-router-dom'

import ModalPage from '../../../components/ModalPage'
import { CHART_TYPE_PATHS } from '../../../configuration/constants'
import { useAppContext } from '../../../context/AppContext'

import PenneoSigningLink from './PenneoSigningLink'

const goalStates = [
  'saxo_house_transfer_documents_signed',
  'saxo_house_transfer_pending',
  'saxo_house_transfer_completed',
]

const SaxoTransferPage = () => {
  const history = useHistory()
  const [timedOut, setTimedOut] = useState(false)
  const [userReturnedToWindow, setUserReturnedToWindow] = useState(false)
  const currentPortfolioId = useSelector(selectCurrentPortfolioId)
  const {
    // @ts-expect-error TS(2339) FIXME: Property 'penneoSigningUrl' does not exist on type 'unknown'.
    penneoSigningUrl,
    // @ts-expect-error TS(2339) FIXME: Property 'saxoHouseTransferState' does not exist on type 'unknown'.
    saxoHouseTransferState,
    // @ts-expect-error TS(2339) FIXME: Property 'saxoHouseTransferDocumentsReadyToSignAt' does not exist on type 'unknown'.
    saxoHouseTransferDocumentsReadyToSignAt,
  } = useObjectSelector(selectCurrentUser)
  const createDocuments = useApi('/saxo_house_transfer/documents', {
    method: 'POST',
    withCredentials: true,
    errorHandling: {
      ignore: {
        client: true,
      },
    },
  })

  const startSigningOfDocuments = useApi('/saxo_house_transfer/documents/signing', {
    method: 'POST',
    withCredentials: true,
    errorHandling: {
      ignore: {
        client: true,
      },
    },
  })

  const handleTimeout = useCallback(() => setTimedOut(true), [])
  const { chartType } = useAppContext()
  const chartParam = findKey(CHART_TYPE_PATHS, (value) => value === chartType)
  const backUrl = `/portefoeljer/${currentPortfolioId}/${chartParam}`

  const transferStarted = saxoHouseTransferState !== 'saxo_house_transfer_not_started'
  const documentsReady = saxoHouseTransferDocumentsReadyToSignAt !== null

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const extractNewState = (newUser: any, _newPortfolio: any) => newUser.saxoHouseTransferState

  useWaitForStateChange('saxo_house_transfer_documents_ready_to_sign', handleTimeout, {
    currentState: saxoHouseTransferState,
    extractNewState,
  })

  useEffect(() => {
    if (documentsReady) return

    createDocuments(undefined)
  }, [createDocuments, documentsReady])

  const completedSigning = goalStates.includes(saxoHouseTransferState)

  useWaitForStateChange(userReturnedToWindow ? goalStates : false, handleTimeout, {
    currentState: saxoHouseTransferState,
    extractNewState,
  })

  useEffect(() => {
    if (transferStarted) return

    history.push(backUrl)
  }, [backUrl, history, transferStarted])

  const handleFocusWindow = useCallback(() => {
    if (userReturnedToWindow) return

    setUserReturnedToWindow(true)
    startSigningOfDocuments(undefined)
  }, [startSigningOfDocuments, userReturnedToWindow])

  const handleClickLink = () => {
    startSigningOfDocuments(undefined)
  }

  if (!transferStarted) return null

  return (
    <ModalPage
      // @ts-expect-error TS(2322) FIXME: Type '{ children: Element; title: string; backUrl:... Remove this comment to see the full error message
      title="Flytning til porteføljeplejeaftale"
      backUrl={backUrl}
      header={
        <Text as="h3" className="ml-auto">
          Opdatering af aftale
        </Text>
      }
      closeButton
    >
      <div className="text-center">
        {completedSigning ? (
          <p>
            Mange tak for din underskrift. Vi glæder os til at gøre det nemmere for dig at være
            investeret hos os.
          </p>
        ) : (
          <PenneoSigningLink
            penneoSigningUrl={penneoSigningUrl}
            onClick={handleClickLink}
            onWindowFocus={handleFocusWindow}
            timedOut={timedOut}
            description={
              <>
                <p>
                  Du er nu klar til at underskrive aftalen med os, der gør det endnu nemmere at være
                  kunde hos Norm Invest.
                </p>
                <p>Du skal blot klikke nedenfor og derefter underskrive aftalen.</p>
              </>
            }
          />
        )}
      </div>
    </ModalPage>
  )
}

export default SaxoTransferPage
