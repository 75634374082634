import BooleanCollection from '@nord/ui/src/components/BooleanCollection'
import Form from '@nord/ui/src/components/Form'
import Icon from '@nord/ui/src/components/Icon'
import Loader from '@nord/ui/src/components/Loader'
import InfoModal from '@nord/ui/src/components/QuestionsSection/QuestionPage/InfoModal'
import Text from '@nord/ui/src/components/Text'
import useApi, { useApiRequest } from '@nord/ui/src/hooks/useApi'
import useObjectSelector from '@nord/ui/src/hooks/useObjectSelector'
import { selectCurrentPortfolio } from '@nord/ui/src/store/current/portfolioId'
import React, { useEffect } from 'react'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { ArrowLeft } from 'react-feather'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { Link, useHistory } from 'react-router-dom'

import usePageLeaveWarning from '../../../../../hooks/usePageLeaveWarning'
import useFinishSustainabilityUpdate from '../hooks/useFinishSustainabilityUpdate'
import useUpdateSustainabilityPreference from '../hooks/useUpdateSustainabilityPreferences'
import { useSustainabilityContext } from '../Provider'

const formatOptions = (data: any) =>
  data.map((item: any) => ({
    label: item,
    value: item,
  }))

const QuestionPage = () => {
  // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'unknown'.
  const { id: portfolioId, sustainabilityPreferenceGoalAnswer } =
    useObjectSelector(selectCurrentPortfolio)
  const history = useHistory()

  usePageLeaveWarning()

  const {
    // @ts-expect-error TS(2339) FIXME: Property 'setQuestionsVersion' does not exist on t... Remove this comment to see the full error message
    setQuestionsVersion,
    // @ts-expect-error TS(2339) FIXME: Property 'setAnswerData' does not exist on type 'u... Remove this comment to see the full error message
    setAnswerData,
    // @ts-expect-error TS(2339) FIXME: Property 'answerData' does not exist on type 'unkn... Remove this comment to see the full error message
    answerData: newAnswerData,
  } = useSustainabilityContext()

  const { answer: newAnswer } = newAnswerData || {}
  const initialAnswer = sustainabilityPreferenceGoalAnswer

  const currentAnswer = newAnswer || initialAnswer

  const fetchInvestmentPlanQuestions = useApi('/investment_profiles')

  const { data } = useApiRequest(fetchInvestmentPlanQuestions, {
    payload: {
      type: 'sustainability_preferences',
    },
    autoCall: true,
  })

  const { data: sustainabilityQuestions, version } = data || {}

  const sustainabilityQuestion = sustainabilityQuestions && sustainabilityQuestions[0]

  const { question, answers, infoText, number } = sustainabilityQuestion || {}

  const options = answers && formatOptions(answers)

  const updateSustainabilityPreferences = useUpdateSustainabilityPreference()
  const finishSustainabilityUpdate = useFinishSustainabilityUpdate()

  useEffect(() => {
    if (!version) return

    setQuestionsVersion(version)
  }, [setQuestionsVersion, version])

  const handleSubmit = async ({ sustainabilityPreferenceGoal }: any) => {
    const answerData = {
      question,
      number,
      answers,
      answerIndex: answers.indexOf(sustainabilityPreferenceGoal),
      answer: sustainabilityPreferenceGoal,
    }

    setAnswerData(answerData)

    const { data: responseData, success } = await updateSustainabilityPreferences({
      portfolio: {
        investmentProfileAttributes: {
          version,
          answers: [answerData],
        },
      },
    })

    const {
      sustainabilityPreferenceLevel,
      user: { initialSustainabilityPreferenceLevel },
    } = responseData

    if (success) {
      finishSustainabilityUpdate(responseData)

      return
    }

    if (initialSustainabilityPreferenceLevel === 'high') {
      history.push(`/portefoeljer/${portfolioId}/anbefaling/sortering`)

      return
    }

    if (sustainabilityPreferenceLevel === 'high') {
      history.push(`/portefoeljer/${portfolioId}/anbefaling/advarsel`)
    }
  }

  return (
    <Row className="my-3">
      {!sustainabilityQuestion ? (
        <Col>
          <Loader className="text-center font-size-lg" />
        </Col>
      ) : (
        <>
          <Col xs={12} className="d-flex">
            <Button
              as={Link}
              to={`/portefoeljer/${portfolioId}/anbefaling`}
              variant="link-dark"
              className="btn-anchor pr-3"
            >
              {/* @ts-expect-error TS(2739) FIXME: Type 'FunctionComponent<IconProps>' is missing the... Remove this comment to see the full error message */}
              <Icon icon={ArrowLeft} size="20" />
            </Button>
            <Text as="h4" align="center">
              {question}
            </Text>
          </Col>
          <InfoModal {...infoText} />
          <Col xs={12} lg={{ span: 8, offset: 2 }}>
            <Form
              initialValues={{ sustainabilityPreferenceGoal: currentAnswer }}
              onSubmit={handleSubmit}
            >
              <Form.FieldGroup name="sustainabilityPreferenceGoal">
                {/* @ts-expect-error TS(2739) FIXME: Type '{ ({ name, options, onChange, onValueChange,... Remove this comment to see the full error message */}
                <Form.Field type="radio" inputComponent={BooleanCollection} options={options} />
              </Form.FieldGroup>
              <div className="d-flex justify-content-center">
                {/* @ts-expect-error TS(2322) FIXME: Type '{ children: string; variant: string; filled:... Remove this comment to see the full error message */}
                <Form.SubmitButton variant="primary" filled>
                  Godkend
                </Form.SubmitButton>
              </div>
            </Form>
          </Col>
        </>
      )}
    </Row>
  )
}

export default QuestionPage
