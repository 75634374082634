import Form from '@nord/ui/src/components/Form'
import PasswordInput from '@nord/ui/src/components/PasswordInput'
import React from 'react'

import validationSchema from './validationSchema'

const labelDescriptions = {
  password: 'Ny adgangskode',
  passwordConfirmation: 'Bekræft ny adgangskode',
}

export interface Props {
  buttonText: string
  disabledInputs: boolean
}

const PasswordForm = ({ buttonText, disabledInputs, ...otherProps }: Props) => (
  <Form
    validationSchema={validationSchema}
    labelDescriptions={labelDescriptions}
    // @ts-expect-error TS(2322) FIXME: Type '{ children: Element[]; validationSchema: Obj... Remove this comment to see the full error message
    validateOnMount
    {...otherProps}
  >
    <Form.FieldGroup name="password">
      {/* @ts-expect-error TS(2322) FIXME: Type '(props: Props & RefAttributes<any>) => React... Remove this comment to see the full error message */}
      <Form.Field inputComponent={PasswordInput} disabled={disabledInputs} autoFocus />
    </Form.FieldGroup>
    <Form.FieldGroup name="passwordConfirmation">
      {/* @ts-expect-error TS(2322) FIXME: Type '(props: Props & RefAttributes<any>) => React... Remove this comment to see the full error message */}
      <Form.Field inputComponent={PasswordInput} disabled={disabledInputs} />
    </Form.FieldGroup>
    <div className="d-flex justify-content-center my-4">
      {/* @ts-expect-error TS(2322) FIXME: Type '{ children: string; variant: string; disable... Remove this comment to see the full error message */}
      <Form.SubmitButton variant="primary" disableOnInvalid>
        {buttonText}
      </Form.SubmitButton>
    </div>
  </Form>
)

export default PasswordForm
