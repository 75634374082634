import useQuery from '@nord/ui/src/hooks/useQuery'
import classNames from 'classnames'
import { format, startOfYear, sub, differenceInBusinessDays, subYears } from 'date-fns'
import React from 'react'

import { useAppContext } from '../../../../context/AppContext'

import CustomDateRangeButton from './CustomDateRangeButton'
import DateButton from './DateButton'
import styles from './index.module.scss'

const currentDate = new Date()

const differenceInBusinessDaysFromNewYear = differenceInBusinessDays(
  currentDate,
  startOfYear(currentDate),
)

const shouldShowCurrentYear = differenceInBusinessDaysFromNewYear > 2

const currentYearData = shouldShowCurrentYear
  ? {
      label: format(currentDate, 'yyyy'),
      value: format(startOfYear(currentDate), 'yyyy-MM-dd'),
    }
  : {
      label: format(subYears(currentDate, 1), 'yyyy'),
      value: format(startOfYear(subYears(currentDate, 1)), 'yyyy-MM-dd'),
    }

const dates = [
  ['1m', format(sub(currentDate, { months: 1 }), 'yyyy-MM-dd')],
  ['3m', format(sub(currentDate, { months: 3 }), 'yyyy-MM-dd')],
  ['6m', format(sub(currentDate, { months: 6 }), 'yyyy-MM-dd')],
  ['1år', format(sub(currentDate, { years: 1 }), 'yyyy-MM-dd')],
  [currentYearData.label, currentYearData.value],
  ['alle', undefined],
] as const

const datesFrom = dates.map((date) => date[1])

const DateSelection = () => {
  const { dateFrom, dateTo, onChangeDate } = useAppContext()

  const { to: queryTo } = useQuery()

  const isCustomDateRangeActive = !datesFrom.includes(dateFrom) || queryTo

  return (
    <div className="position-relative">
      <div className={styles.dateSelectionWrapper}>
        <div className="container-fluid mx-lg-3 d-flex flex-row justify-content-center justify-content-lg-start">
          {dates.map(([label, date]) => (
            <DateButton
              key={label}
              onClick={() => onChangeDate(date)}
              active={date === dateFrom && !dateTo}
              className={classNames({
                'd-none d-lg-block': label === format(currentDate, 'yyyy'),
              })}
            >
              {label}
            </DateButton>
          ))}
          <CustomDateRangeButton active={isCustomDateRangeActive} />
        </div>
      </div>
    </div>
  )
}

export default DateSelection
