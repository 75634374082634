import { useEffect, useContext } from 'react'

import ModalPageContext from '../components/ModalPage/ModalPageContext'

const usePageLeaveWarning = () => {
  // @ts-expect-error TS(2339) FIXME: Property 'onChangeWithWarning' does not exist on t... Remove this comment to see the full error message
  const { onChangeWithWarning } = useContext(ModalPageContext)

  useEffect(() => {
    onChangeWithWarning(true)

    return () => onChangeWithWarning(false)
  }, [onChangeWithWarning])
}

export default usePageLeaveWarning
