import Icon from '@nord/ui/src/components/Icon'
import React from 'react'
import Nav from 'react-bootstrap/Nav'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import X from 'react-feather/dist/icons/x'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { Link } from 'react-router-dom'

import styles from './index.module.scss'

export interface Props {
  backUrl: string
}

const CloseNavItem = ({ backUrl }: Props) => (
  <Nav.Item className={styles.closeNavItem}>
    <Nav.Link as={Link} to={backUrl} className="link-dark">
      <Icon icon={X} data-cy="close-button" />
    </Nav.Link>
  </Nav.Item>
)

export default CloseNavItem
