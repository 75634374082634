import Loader from '@nord/ui/src/components/Loader'
import Text from '@nord/ui/src/components/Text'
import { numberToCurrency } from '@nord/ui/src/utilities/numberFormatter'
import React from 'react'

export interface Props {
  liveData?: {
    value: number
  }
  isLoading: boolean
}

const HistoricalReturns = ({ liveData, isLoading, ...otherProps }: Props) => (
  <div {...otherProps}>
    <Text as="h6" align="center">
      Afkast
    </Text>
    <Text as="h1" align="center" variant="white" className="mb-0">
      {isLoading || liveData === undefined ? <Loader /> : numberToCurrency(liveData.value)}
    </Text>
  </div>
)

HistoricalReturns.defaultProps = {
  liveData: undefined,
}

export default HistoricalReturns
