import { useQuery } from '@tanstack/react-query'
import type { UseQueryResult, UseQueryOptions } from '@tanstack/react-query'

type QueryKey = [string, ...unknown[]]

type Options<TQueryFnData, TError = unknown, TData = TQueryFnData> = Omit<
  UseQueryOptions<TQueryFnData, TError, TData, QueryKey>,
  'queryKey' | 'queryFn'
>

function useRequest<TQueryFnData, TFnArgs extends unknown[]>(
  fn: (...as: TFnArgs) => Promise<TQueryFnData>,
  args: TFnArgs,
  options?: Options<TQueryFnData>,
): UseQueryResult<TQueryFnData> {
  const queryKey: QueryKey = [fn.name, ...args]

  const queryFn = () => fn(...args)

  return useQuery({
    ...options,
    queryKey,
    queryFn,
  })
}

export default useRequest
