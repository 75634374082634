import BaffleButton from '@nord/ui/src/components/BaffleButton'
import Loader from '@nord/ui/src/components/Loader'
import Text from '@nord/ui/src/components/Text'
import { getConfig } from '@nord/ui/src/configuration'
import useApi, { useApiRequest } from '@nord/ui/src/hooks/useApi'
import { selectCurrentUser } from '@nord/ui/src/store/current/user'
import countBy from 'lodash/countBy'
import isEmpty from 'lodash/isEmpty'
import React, { useMemo } from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { useSelector } from 'react-redux'

import CenteredTextSection from '../../../../components/CenteredTextSection'

import AccountCreationsContext from './AccountCreationsContext'
import PortfolioRow from './PortfolioRow'

const getInvestmentPlanUrl = getConfig('dynamicUrls.onboarding.investmentPlan')

const pensionNewInvestmentPlanUrl = getInvestmentPlanUrl('pension')
const privateNewInvestmentPlanUrl = getInvestmentPlanUrl('private')

const AccountCreations = () => {
  const { accountType, appState } = useSelector(selectCurrentUser)

  const isOnboarded = appState === 'saxo_onboarded' || appState === 'invested'

  const fetchPortfolios = useApi('/portfolios', {
    method: 'GET',
    withCredentials: true,
  })

  const {
    data: onboardingPortfolios,
    loading,
    called,
    request: refetchPortfolios,
  } = useApiRequest(fetchPortfolios, {
    autoCall: true,
    payload: {
      filter: {
        state: '!ready_to_onboard',
      },
      full: true,
    },
  })

  const context = useMemo(
    () => ({
      refetchPortfolios,
    }),
    [refetchPortfolios],
  )

  const { private: privateOnboardingCount, pension: pensionOnboardingCount } = {
    pension: 0,
    private: 0,
    ...countBy(onboardingPortfolios, (portfolio) => portfolio.onboardingFlow),
  }

  const allowNewPrivatePortfolio = privateOnboardingCount === 0
  const allowNewPensionPortfolio = pensionOnboardingCount === 0 && accountType === 'private_account'

  return (
    <AccountCreationsContext.Provider value={context}>
      <div className="py-5">
        <Text as="h4" align="center" className="my-3">
          Dine depotoprettelser
        </Text>
        {loading && !called ? (
          <Loader className="text-center font-size-xxl" />
        ) : (
          <>
            <Row>
              {loading && <Loader className="font-size-lg" centeredInParent />}
              <Col>
                {isEmpty(onboardingPortfolios) ? (
                  <CenteredTextSection>
                    <Text as="div" weight="bold">
                      {isOnboarded
                        ? 'Du har ingen depotoprettelser i gang.'
                        : 'Dit depot er under oprettelse, og vil være klart hurtigst muligt.'}
                    </Text>
                    <Text as="div" className="mb-3">
                      Hvis du ønsker et ekstra depot, eller at flytte en pensionsopsparing så kan du
                      starte her:
                    </Text>
                  </CenteredTextSection>
                ) : (
                  <>
                    <Text as="p" align="center">
                      Her ses en oversigt over de porteføljer du har under oprettelse.
                    </Text>
                    <ul className="list-unstyled px-2">
                      {onboardingPortfolios.map((portfolio: any) => (
                        <PortfolioRow
                          key={portfolio.id}
                          showDeleteButton={isOnboarded}
                          {...portfolio}
                        />
                      ))}
                    </ul>
                  </>
                )}
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <div className="mt-3 d-flex flex-column align-items-center children-my-2 flex-lg-row justify-content-lg-center children-my-lg-0 children-mx-lg-2">
                  {allowNewPrivatePortfolio && (
                    <BaffleButton variant="primary" as="a" href={privateNewInvestmentPlanUrl}>
                      Tilføj et ekstra frie midler depot
                    </BaffleButton>
                  )}
                  {allowNewPensionPortfolio && (
                    <BaffleButton variant="primary" as="a" href={pensionNewInvestmentPlanUrl}>
                      Opret eller flyt pensionsdepot
                    </BaffleButton>
                  )}
                </div>
              </Col>
            </Row>
          </>
        )}
      </div>
    </AccountCreationsContext.Provider>
  )
}

export default AccountCreations
