import useObjectSelector from '@nord/ui/src/hooks/useObjectSelector'
import {
  changeCurrentPortfolio,
  selectCurrentPortfolio,
} from '@nord/ui/src/store/current/portfolioId'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { useAppContext } from '../../../../context/AppContext'

const useUpdatePortfolioTotalValue = (liveData?: { value?: number }, loadingLiveData?: boolean) => {
  const dispatch = useDispatch()
  const { dateTo, chartType } = useAppContext()

  // @ts-expect-error TS(2339) FIXME: Property 'appState' does not exist on type 'unknow... Remove this comment to see the full error message
  const { appState: portfolioAppState, totalValue } = useObjectSelector(selectCurrentPortfolio)

  const depositReceived =
    portfolioAppState === 'invested' || portfolioAppState === 'deposit_received'

  useEffect(() => {
    if (!depositReceived) return
    if (chartType !== 'values') return
    if (loadingLiveData) return
    if (!liveData) return
    if (dateTo) return

    const { value } = liveData
    if (value === undefined) return
    if (value === totalValue) return

    dispatch(
      changeCurrentPortfolio({
        totalValue: value,
      }),
    )
  }, [dateTo, dispatch, liveData, loadingLiveData, totalValue, chartType, depositReceived])
}

export default useUpdatePortfolioTotalValue
