import useObjectSelector from '@nord/ui/src/hooks/useObjectSelector'
import {
  changeCurrentPortfolio,
  selectCurrentPortfolio,
} from '@nord/ui/src/store/current/portfolioId'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { useAppContext } from '../../../../context/AppContext'
import useIsPayoutAccount from '../../../../hooks/useIsPayoutAccount'

const useUpdatePortfolioReturnInDkk = (
  liveData?: { returnInDkk?: number },
  loadingLiveData?: boolean,
) => {
  const dispatch = useDispatch()
  const isPayoutAccount = useIsPayoutAccount()
  const { dateTo, chartType } = useAppContext()

  // @ts-expect-error TS(2339) FIXME: Property 'appState' does not exist on type 'unknow... Remove this comment to see the full error message
  const { appState: portfolioAppState, returnInDkk } = useObjectSelector(selectCurrentPortfolio)
  const isInvested = portfolioAppState === 'invested'

  useEffect(() => {
    if (!isInvested) return
    if (isPayoutAccount) return
    if (chartType !== 'values') return
    if (loadingLiveData) return
    if (!liveData) return
    if (dateTo) return

    const { returnInDkk: liveReturn } = liveData

    if (liveReturn === undefined) return
    if (liveReturn === returnInDkk) return

    dispatch(
      changeCurrentPortfolio({
        returnInDkk: liveReturn,
      }),
    )
  }, [
    chartType,
    dateTo,
    dispatch,
    isInvested,
    isPayoutAccount,
    liveData,
    loadingLiveData,
    returnInDkk,
  ])
}

export default useUpdatePortfolioReturnInDkk
