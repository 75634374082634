import useObjectSelector from '@nord/ui/src/hooks/useObjectSelector'
import { selectCurrentUser } from '@nord/ui/src/store/current/user'
import React, { useMemo } from 'react'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { Link } from 'react-router-dom'

import AccountInformation from './AccountInformation'
import useDepositReceivedData from './useDepositReceivedData'

const useStateMessageData = () => {
  // @ts-expect-error TS(2339) FIXME: Property 'firstName' does not exist on type 'unkno... Remove this comment to see the full error message
  const { firstName } = useObjectSelector(selectCurrentUser)
  const { title: depositReceivedTitle, message: depositReceivedMessage } = useDepositReceivedData()

  const data = useMemo(
    () => ({
      payoutAccount: {
        title: 'Pension udbetalingskonto',
        message: (
          <>
            Dette er din udbetalingskonto for pension. Dvs. at når din pension udbetales, så
            overføres beløbet til denne konto. Herfra kan du overføre pengene til din egen bank.
            <br />
            <Link to="/konto/kontant_udbetaling/verificer">Gå til bank overførsel</Link>
          </>
        ),
      },
      readyToOnboard: {
        title: 'Tak for din oprettelse',
        message:
          'Din portefølje oprettelse er under behandling. Vi sender dig en email når dit depot er klar til indbetaling.',
      },
      createdInSaxo: {
        title: 'Du kan nu overføre til din konto',
        message: (
          <>
            Din konto er oprettet og du kan nu overføre penge til din konto. Minimum for investering
            hos os er på 10.000 kr.
            <br /> Så snart indbetalingen er sket, bliver midlerne investeret og du kan følge din
            investering her.
            <br /> Bemærk, at der kan gå op til 2 hverdage.
          </>
        ),
        children: <AccountInformation />,
      },
      depositReceived: {
        title: depositReceivedTitle,
        message: depositReceivedMessage,
      },
      oldInvested: {
        title: 'Periode for kort',
        message: 'Perioden du har valgt, har desværre ikke nok data til at vise en graf.',
      },
      newInvested: {
        title: `Hej ${firstName}`,
        message: (
          <>
            Tillykke med din investering! 🥳 <br />
            Der vil gå 2-3 hverdage, førend du kan følge udviklingen af din investering her på
            grafen.
          </>
        ),
      },
    }),
    [depositReceivedTitle, depositReceivedMessage, firstName],
  )

  return data
}

export default useStateMessageData
