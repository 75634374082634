import CurrencyInput from '@nord/ui/src/components/CurrencyInput'
import Form from '@nord/ui/src/components/Form'
import Loader from '@nord/ui/src/components/Loader'
import Select from '@nord/ui/src/components/Select'
import useApi, { useApiRequest } from '@nord/ui/src/hooks/useApi'
import useObjectSelector from '@nord/ui/src/hooks/useObjectSelector'
import useQuery from '@nord/ui/src/hooks/useQuery'
import { selectPortfolios, selectPortfolioById } from '@nord/ui/src/store/current/portfolios'
import { numberToCurrency } from '@nord/ui/src/utilities/numberFormatter'
import React, { useEffect, useState } from 'react'
import Alert from 'react-bootstrap/Alert'
import { useSelector } from 'react-redux'

import CenteredTextSection from '../../../../components/CenteredTextSection'
import usePageLeaveWarning from '../../../../hooks/usePageLeaveWarning'
import { usePortfolioChartLiveQueries } from '../../../../hooks/usePortfolioChartLiveQueries'

import ConfirmationPage from './ConfirmationPage'
import ErrorPage from './ErrorPage'
import labelDescriptions from './labelDecriptions'
import validationSchema from './validationSchema'

const formatAccountData = (options: { value: number; label: string }[], id: number) => {
  const formattedData = options.find(({ value }) => value === id)

  return formattedData?.label
}

const selectProps = {
  isSearchable: false,
  placeholder: 'Vælg konto...',
}

const WithdrawalPage = () => {
  const portfoliosData = useSelector(selectPortfolios) as {
    id: number
    value: number
    label: string
    title: string
    registrationNumber: string
    saxoAccountNumber: string
  }[]
  const verificationSuccess = useQuery('success')

  usePageLeaveWarning()

  // @ts-expect-error TS(2339) FIXME: Property 'createdInSaxoAt' does not exist on type ... Remove this comment to see the full error message
  const filteredPortfoliosData = portfoliosData.filter(({ createdInSaxoAt }) => !!createdInSaxoAt)

  const queries = usePortfolioChartLiveQueries(
    filteredPortfoliosData.map((portfolio) => ({
      chartType: 'values',
      portfolioId: portfolio.id,
    })),
  )

  const hasOnlyOnePortfolio = filteredPortfoliosData.length === 1
  const portfoliosOptions = filteredPortfoliosData.map(
    ({ id, title, registrationNumber, saxoAccountNumber }) => {
      const totalValueFormatted = queries
        .flatMap((query) =>
          id === query.data?.portfolioId ? [numberToCurrency(query.data.value)] : [],
        )
        .at(0)

      return {
        value: id,
        label: totalValueFormatted
          ? `${title}: ${registrationNumber} - ${saxoAccountNumber} (${totalValueFormatted})`
          : `${title}: ${registrationNumber} - ${saxoAccountNumber}`,
      }
    },
  )

  const defaultPortfolioId = hasOnlyOnePortfolio ? portfoliosOptions[0].value : null

  const [portfolioId, setPortfolioId] = useState(defaultPortfolioId)
  const [isWithdrawalCompleted, setWithdrawalIsCompleted] = useState(false)
  const [confirmationPageData, setConfirmationPageData] = useState({})

  // @ts-expect-error TS(2339) FIXME: Property 'pensionDepot' does not exist on type 'un... Remove this comment to see the full error message
  const { pensionDepot: isPensionPortfolio } = useObjectSelector(selectPortfolioById(portfolioId))

  useEffect(() => {
    if (!defaultPortfolioId) return

    setPortfolioId(defaultPortfolioId)
  }, [defaultPortfolioId])

  const fetchDataForWithdrawal = useApi(`/portfolios/${portfolioId}/saxo_cash_withdrawals/new`, {
    method: 'GET',
    withCredentials: true,
  })

  const { data, loading, request: getDataForWithdrawal } = useApiRequest(fetchDataForWithdrawal)

  useEffect(() => {
    if (verificationSuccess === 'false') return
    if (!portfolioId) return
    if (isPensionPortfolio) return

    getDataForWithdrawal()
  }, [portfolioId, getDataForWithdrawal, isPensionPortfolio, verificationSuccess])

  const withdrawCash = useApi(`/portfolios/${portfolioId}/saxo_cash_withdrawals`, {
    method: 'POST',
    withCredentials: true,
    errorHandling: {
      ignore: {
        client: true,
      },
    },
  })

  const { request: callWithdrawCash } = useApiRequest(withdrawCash)

  const {
    externalAccounts,
    availableAmount,
    availableAmountAfterFees,
    approximateYearlyFees,
    unpaidFees,
  } = data || {}

  const hasOnlyOneExternalAccount = externalAccounts && externalAccounts.length === 1
  const externalAccountOptions = externalAccounts?.map(
    ({ id, name }: { id: number; name: string }) => ({
      value: id,
      label: name,
    }),
  )
  const defaultExternalAccountId = hasOnlyOneExternalAccount
    ? externalAccountOptions[0].value
    : null

  const initialValues = {
    portfolioId: defaultPortfolioId,
    externalAccountId: defaultExternalAccountId,
  }

  const handlePortfolioIdChange = (id: number) => setPortfolioId(id)

  const handleSubmit = async (values: {
    amount: number
    externalAccountId: number
    transactionMessage: unknown
    portfolioId: number
  }) => {
    const {
      amount,
      externalAccountId,
      transactionMessage,
      portfolioId: submittedPortfolioId,
    } = values
    setConfirmationPageData({
      amount,
      accountFrom: formatAccountData(portfoliosOptions, submittedPortfolioId),
      accountTo: formatAccountData(externalAccountOptions, externalAccountId),
      transactionMessage,
    })
    const response = await callWithdrawCash({
      portfolio: { amount, externalAccountId, transactionMessage },
    })

    const { success } = response

    setWithdrawalIsCompleted(success)

    return response
  }

  if (verificationSuccess === 'false') return <ErrorPage />

  if (isWithdrawalCompleted) return <ConfirmationPage data={confirmationPageData} />

  return (
    <CenteredTextSection>
      {isPensionPortfolio && (
        <Alert variant="danger">
          Du kan ikke hæve fra din pensionopsparing, før din pensionsudbetaling er startet. Har du
          spørgsmål, eller ønsker du at hæve din pension før tid, så kontakt os.
        </Alert>
      )}
      <Form
        // @ts-expect-error TS(2322) FIXME: Type '{ children: Element[]; className: string; on... Remove this comment to see the full error message
        className="text-left mb-6"
        onSubmit={handleSubmit}
        initialValues={initialValues}
        labelDescriptions={labelDescriptions}
        validationSchema={validationSchema(availableAmountAfterFees)}
        enableReinitialize
      >
        <Form.FieldGroup name="portfolioId">
          <Form.Field
            // @ts-expect-error TS(2739) FIXME: Type '{ ({ onChange, onValueChange, onSelect, opti... Remove this comment to see the full error message
            inputComponent={Select}
            options={portfoliosOptions}
            onValueChange={handlePortfolioIdChange}
            {...selectProps}
          />
        </Form.FieldGroup>
        <Form.FieldGroup
          loading={loading}
          help="Du kan kun overføre til godkendte kontoer i listen. En konto bliver godkendt og tilgængelig for udbetaling så snart der bliver overført penge fra kontoen til Saxo Bank. Ønsker du dette så overfør blot 1 krone for at få den godkendt. Husk det skal være din egen konto, da Saxo Bank ikke modtager 3. parts indbetalinger."
          name="externalAccountId"
        >
          <Form.Field
            // @ts-expect-error TS(2322) FIXME: Type '{ ({ onChange, onValueChange, onSelect, opti... Remove this comment to see the full error message
            inputComponent={Select}
            options={externalAccountOptions}
            noOptionsMessage={() => 'Du har ikke nogen godkendte kontoer til overførelse.'}
            isDisabled={loading || isPensionPortfolio || !portfolioId}
            {...selectProps}
          />
        </Form.FieldGroup>
        <Form.FieldGroup
          loading={loading}
          name="amount"
          help={
            loading ? (
              <Loader className="pl-2" />
            ) : (
              portfolioId &&
              !isPensionPortfolio && (
                <>
                  Du har på nuværende tidspunkt <strong>{numberToCurrency(availableAmount)}</strong>{' '}
                  i kontanter på din konto. Husk at efterlad et mindre beløb til at dække betaling
                  af de løbende omkostninger. De samlede omkostninger for de næste 12 måneder er
                  højest <strong>{numberToCurrency(approximateYearlyFees)}</strong>, og dine
                  ubetalte omkostninger er indtil videre{' '}
                  <strong>{numberToCurrency(unpaidFees)}</strong>
                </>
              )
            )
          }
        >
          {/* @ts-expect-error TS(2739) FIXME: Type '{ ({ maskOptions, value, ...otherProps }: Cu... Remove this comment to see the full error message */}
          <Form.Field as={CurrencyInput} disabled={loading || isPensionPortfolio || !portfolioId} />
        </Form.FieldGroup>
        <Form.FieldGroup loading={loading} name="transactionMessage">
          {/* @ts-expect-error TS(2322) FIXME: Type '{ disabled: any; }' is not assignable to typ... Remove this comment to see the full error message */}
          <Form.Field disabled={loading || isPensionPortfolio || !portfolioId} />
        </Form.FieldGroup>
        <div className="d-flex justify-content-center my-4">
          {/* @ts-expect-error TS(2322) FIXME: Type '{ children: string; variant: string; disable... Remove this comment to see the full error message */}
          <Form.SubmitButton variant="primary" disabled={isPensionPortfolio}>
            Udbetal
          </Form.SubmitButton>
        </div>
      </Form>
    </CenteredTextSection>
  )
}

export default WithdrawalPage
