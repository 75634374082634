import BaffleButton from '@nord/ui/src/components/BaffleButton'
import DateRangeInput from '@nord/ui/src/components/DateRangeInput'
import useObjectSelector from '@nord/ui/src/hooks/useObjectSelector'
import { selectCurrentPortfolio } from '@nord/ui/src/store/current/portfolioId'
import { formatISO, isToday, parseISO } from 'date-fns'
import React, { useState } from 'react'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Overlay from 'react-bootstrap/Overlay'
import Popover from 'react-bootstrap/Popover'
import Row from 'react-bootstrap/Row'

import { useAppContext } from '../../../../context/AppContext'

const formatDate = (date: number | Date) =>
  formatISO(date, {
    representation: 'date',
  })

export interface CustomDateRangePopoverProps {
  onClose(): void
  target: HTMLElement
  show: boolean
}

const CustomDateRangePopover = ({ target, show, onClose }: CustomDateRangePopoverProps) => {
  const { onChangeDate, dateFrom } = useAppContext()

  // @ts-expect-error TS(2339) FIXME: Property 'investedAt' does not exist on type 'unkn... Remove this comment to see the full error message
  const { investedAt } = useObjectSelector(selectCurrentPortfolio)

  const investedDate = formatDate(parseISO(investedAt))
  const todayDate = formatDate(new Date())
  const [dateRangeValues, setDateRangeValues] = useState({})

  const dateRangeValuesWithDefaults = {
    // @ts-expect-error TS(2339) FIXME: Property 'from' does not exist on type '{}'.
    from: dateRangeValues.from !== undefined ? dateRangeValues.from : dateFrom || investedDate,
    // @ts-expect-error TS(2339) FIXME: Property 'to' does not exist on type '{}'.
    to: dateRangeValues.to !== undefined ? dateRangeValues.to : todayDate,
  }

  const handleDateRangeValuesChange = (value: Record<string, unknown>) =>
    setDateRangeValues({ ...dateRangeValues, ...value })

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    const { from, to } = dateRangeValuesWithDefaults
    const isDateToToday = isToday(parseISO(to))

    if (isDateToToday) {
      onChangeDate(from)
      onClose()
    } else {
      onChangeDate(from, to)
      onClose()
    }
  }

  return (
    <Overlay show={show} target={target} placement="bottom" onHide={onClose} rootClose>
      {/* @ts-expect-error TS(2741) FIXME: Property 'id' is missing in type '{ children: Elem... Remove this comment to see the full error message */}
      <Popover>
        <Popover.Content className="p-4">
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col xs={12}>
                <DateRangeInput
                  value={dateRangeValuesWithDefaults}
                  min={investedDate}
                  max={todayDate}
                  onValueChange={handleDateRangeValuesChange}
                />
              </Col>
            </Row>
            <div className="d-flex justify-content-center mt-4">
              <BaffleButton
                type="submit"
                disabled={dateRangeValuesWithDefaults.from === dateRangeValuesWithDefaults.to}
              >
                Vælg
              </BaffleButton>
            </div>
          </Form>
        </Popover.Content>
      </Popover>
    </Overlay>
  )
}

export default CustomDateRangePopover
