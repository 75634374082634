import Text from '@nord/ui/src/components/Text'
import React from 'react'

import CenteredTextSection from '../../../../components/CenteredTextSection'

import ResultPage from './ResultPage'
import StartPage from './StartPage'

export interface Props {
  children: React.ReactNode
}

const CashDeposit = ({ children }: Props) => (
  <CenteredTextSection>
    <Text as="h4" align="center" className="mt-3">
      Nem overførsel til din konto i Saxo Bank
    </Text>
    {children}
  </CenteredTextSection>
)

CashDeposit.ResultPage = ResultPage
CashDeposit.StartPage = StartPage

export default CashDeposit
