import Loader from '@nord/ui/src/components/Loader'
import Text from '@nord/ui/src/components/Text'
import { numberToPercent } from '@nord/ui/src/utilities/numberFormatter'
import React from 'react'

export interface Props {
  liveData?: {
    value: number
    return?: number
  }
  isLoading: boolean
}

const TimeWeightedReturns = ({ liveData, isLoading, ...otherProps }: Props) => (
  <div {...otherProps}>
    <Text as="h6" align="center">
      Afkast
    </Text>
    <Text as="h1" align="center" variant="primary" className="mb-0">
      {isLoading || liveData === undefined || liveData.return ? (
        <Loader />
      ) : (
        numberToPercent(liveData.value - 1, { maximumFractionDigits: 2 })
      )}
    </Text>
  </div>
)

TimeWeightedReturns.defaultProps = {
  liveData: undefined,
}

export default TimeWeightedReturns
