import Sustainability from '@nord/ui/src/components/Sustainability'
import { selectCurrentPortfolioId } from '@nord/ui/src/store/current/portfolioId'
import React from 'react'
import { useSelector } from 'react-redux'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { useHistory } from 'react-router-dom'

import usePageLeaveWarning from '../../../../../hooks/usePageLeaveWarning'
import useFinishSustainabilityUpdate from '../hooks/useFinishSustainabilityUpdate'
import useSustainabilityRedirect from '../hooks/useSustainabilityRedirect'
import useUpdateSustainabilityPreference from '../hooks/useUpdateSustainabilityPreferences'
import { useSustainabilityContext } from '../Provider'

export interface WrapperProps {
  children: React.ReactNode
}

const Wrapper = ({ children }: WrapperProps) => <div className="my-3">{children}</div>

const SortingPage = () => {
  const history = useHistory()
  const portfolioId = useSelector(selectCurrentPortfolioId)

  // @ts-expect-error TS(2339) FIXME: Property 'answerData' does not exist on type 'unkn... Remove this comment to see the full error message
  const { answerData, questionsVersion, setPreferencesOrder } = useSustainabilityContext()

  useSustainabilityRedirect()
  usePageLeaveWarning()

  const updateSustainabilityPreferences = useUpdateSustainabilityPreference()
  const finishSustainabilityUpdate = useFinishSustainabilityUpdate()

  const handleSubmit = async (newSustainabilityPreferencesOrder: any) => {
    const { data, success } = await updateSustainabilityPreferences({
      portfolio: {
        investmentProfileAttributes: {
          version: questionsVersion,
          answers: [answerData],
        },
        sustainabilityPreferencesOrder: newSustainabilityPreferencesOrder,
      },
    })

    if (success) {
      finishSustainabilityUpdate(data)

      return
    }

    setPreferencesOrder(newSustainabilityPreferencesOrder)
    history.push(`/portefoeljer/${portfolioId}/anbefaling/advarsel`)
  }

  return (
    <Sustainability.Sorting
      // @ts-expect-error TS(2322) FIXME: Type '({ children }: WrapperProps) => JSX.Element'... Remove this comment to see the full error message
      wrapperComponent={Wrapper}
      submitButtonProps={{ filled: true }}
      onSubmit={handleSubmit}
      withSubmitButton
    />
  )
}

export default SortingPage
