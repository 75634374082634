import Card from '@nord/ui/src/components/Card'
import DescriptionList from '@nord/ui/src/components/DescriptionList'
import Text from '@nord/ui/src/components/Text'
import useObjectSelector from '@nord/ui/src/hooks/useObjectSelector'
import { selectCurrentPortfolio } from '@nord/ui/src/store/current/portfolioId'
import React from 'react'

const AccountInformation = () => {
  // @ts-expect-error TS(2339) FIXME: Property 'saxoAccountNumber' does not exist on typ... Remove this comment to see the full error message
  const { saxoAccountNumber, registrationNumber, riskScoreTitle, esgTitle } =
    useObjectSelector(selectCurrentPortfolio)

  return (
    <>
      <Card className="mt-6 mx-6">
        <Card.Body>
          <DescriptionList>
            <DescriptionList.Item
              label="Reg.- og kontonummer"
              value={`${registrationNumber} - ${saxoAccountNumber}`}
            />
          </DescriptionList>
        </Card.Body>
      </Card>
      <Text as="p" align="center" className="mt-5">
        Dine midler vil blive investeret med risikoprofil {riskScoreTitle}
        {esgTitle && ` ${esgTitle}`}. Hvis du ønsker at ændre risikoprofilen så kontakt os.
      </Text>
    </>
  )
}

export default AccountInformation
