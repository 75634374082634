import Icon from '@nord/ui/src/components/Icon'
import useObjectSelector from '@nord/ui/src/hooks/useObjectSelector'
import { selectCurrentPortfolio } from '@nord/ui/src/store/current/portfolioId'
import React from 'react'
import Nav from 'react-bootstrap/Nav'
import Tab from 'react-bootstrap/Tab'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import X from 'react-feather/dist/icons/x'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { Switch, Route, useRouteMatch, Link, Redirect } from 'react-router-dom'

import CloseNavItem from '../../../components/CloseNavItem'
import ModalPage from '../../../components/ModalPage'
import { CHART_TYPE_PATHS } from '../../../configuration/constants'
import { useAppContext } from '../../../context/AppContext'
import useIsPayoutAccount from '../../../hooks/useIsPayoutAccount'
import usePortfolioInformationSubpage from '../../../hooks/usePortfolioInformationSubpage'

import Advisory from './Advisory'
import Allocation from './Allocation'
import Information from './Information'
import NewDeposit from './NewDeposit'
import OrderHandling from './OrderHandling'
import SaxoReports from './SaxoReports'
import TitleChange from './TitleChange'
import Transactions from './Transactions'

const validPortfolioSubpages = [
  'kontobevaegelser',
  'ny_indbetaling',
  'forhandsgodkendelse',
  'anbefaling',
  'portefolje',
  'rapporter',
  'rediger',
]

const portfolioActiveKeys = {
  undefined: 'information',
  ny_indbetaling: 'newDeposit', // eslint-disable-line camelcase
  kontobevaegelser: 'transactions',
  forhandsgodkendelse: 'orderHandling',
  anbefaling: 'sustainabilityPreference',
  portefolje: 'allocation',
  rapporter: 'reports',
  rediger: 'titleChange',
}

const PortfolioPage = () => {
  const isPayoutAccount = useIsPayoutAccount()
  // @ts-expect-error TS(2339) FIXME: Property 'appState' does not exist on type 'unknow... Remove this comment to see the full error message
  const { appState } = useObjectSelector(selectCurrentPortfolio)

  const { chartType } = useAppContext()
  const { url } = useRouteMatch()
  const validPath = usePortfolioInformationSubpage()

  const chartParam = Object.keys(CHART_TYPE_PATHS).find(
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    (key) => CHART_TYPE_PATHS[key] === chartType,
  )

  const isInvested = appState === 'invested' || appState === 'deposit_recieved'

  let defaultBackParam
  if (isInvested) defaultBackParam = chartParam
  else defaultBackParam = 'information'

  const backUrl = `${url}/${defaultBackParam}`

  if (!validPortfolioSubpages.includes(validPath) && validPath !== undefined)
    return <Redirect to={backUrl} />

  // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const activeSection = portfolioActiveKeys[validPath]

  return (
    <Tab.Container defaultActiveKey={activeSection}>
      <ModalPage
        header={
          <>
            <Nav variant="tabs" className="w-100 d-none d-lg-flex flex-column flex-lg-row" fill>
              <Nav.Item>
                <Nav.Link as={Link} to={url} eventKey="information" className="link-dark">
                  Information
                </Nav.Link>
              </Nav.Item>
              {!isPayoutAccount && (
                <Nav.Item>
                  <Nav.Link
                    as={Link}
                    to={`${url}/ny_indbetaling`}
                    eventKey="newDeposit"
                    className="link-dark"
                  >
                    Ny indbetaling
                  </Nav.Link>
                </Nav.Item>
              )}
              <Nav.Item>
                <Nav.Link
                  as={Link}
                  to={`${url}/kontobevaegelser`}
                  eventKey="transactions"
                  className="link-dark"
                >
                  Kontobevægelser
                </Nav.Link>
              </Nav.Item>
              {!isPayoutAccount && (
                <>
                  <Nav.Item>
                    <Nav.Link
                      as={Link}
                      to={`${url}/portefolje`}
                      eventKey="allocation"
                      className="link-dark"
                    >
                      Portefølje
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      as={Link}
                      to={`${url}/rapporter`}
                      eventKey="reports"
                      className="link-dark"
                    >
                      Rapporter
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      as={Link}
                      to={`${url}/forhandsgodkendelse`}
                      eventKey="orderHandling"
                      className="link-dark"
                    >
                      Forhåndsgodkendelse
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      as={Link}
                      to={`${url}/anbefaling`}
                      eventKey="sustainabilityPreference"
                      className="link-dark"
                    >
                      Anbefaling
                    </Nav.Link>
                  </Nav.Item>
                </>
              )}
              <CloseNavItem backUrl={backUrl} />
            </Nav>
            <div className="d-block ml-auto p-2 d-lg-none">
              <Link to={backUrl} className="link-dark">
                <Icon icon={X} size={2} />
              </Link>
            </div>
          </>
        }
        backUrl={backUrl}
        // @ts-expect-error TS(2322) FIXME: Type '{ children: Element; header: Element; backUr... Remove this comment to see the full error message
        modalHeaderClassName="p-0"
      >
        <Tab.Content>
          <Switch>
            <Tab.Pane as={Route} path={url} eventKey="information" exact>
              <Information />
            </Tab.Pane>
            <Tab.Pane as={Route} path={`${url}/kontobevaegelser`} eventKey="transactions" exact>
              <Transactions />
            </Tab.Pane>
            <Tab.Pane as={Route} path={`${url}/ny_indbetaling`} eventKey="newDeposit" exact>
              <NewDeposit />
            </Tab.Pane>
            <Tab.Pane as={Route} path={`${url}/portefolje`} eventKey="allocation" exact>
              <Allocation />
            </Tab.Pane>
            <Tab.Pane as={Route} path={`${url}/rapporter`} eventKey="reports" exact>
              <SaxoReports />
            </Tab.Pane>
            <Tab.Pane as={Route} path={`${url}/forhandsgodkendelse`} eventKey="orderHandling" exact>
              <OrderHandling />
            </Tab.Pane>
            <Tab.Pane as={Route} path={`${url}/rediger`} eventKey="titleChange" exact>
              <TitleChange />
            </Tab.Pane>
            <Tab.Pane eventKey="sustainabilityPreference">
              <Advisory.Provider>
                <Route path={`${url}/anbefaling`} exact>
                  <Advisory.IntroPage />
                </Route>
                <Route path={`${url}/anbefaling/sporgsmal`} exact>
                  <Advisory.QuestionPage />
                </Route>
                <Route path={`${url}/anbefaling/sortering`} exact>
                  <Advisory.SortingPage />
                </Route>
                <Route path={`${url}/anbefaling/advarsel`} exact>
                  <Advisory.WarningPage />
                </Route>
              </Advisory.Provider>
            </Tab.Pane>
          </Switch>
        </Tab.Content>
      </ModalPage>
    </Tab.Container>
  )
}

export default PortfolioPage
