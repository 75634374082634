import Text from '@nord/ui/src/components/Text'
import { numberToCurrency } from '@nord/ui/src/utilities/numberFormatter'
import React, { useMemo } from 'react'

export interface ReturnTextProps {
  value: number
}

const ReturnText = ({ value }: ReturnTextProps) => {
  const variant = useMemo(() => {
    if (value > 0) return 'dark-green'
    if (value < 0) return 'danger'

    return undefined
  }, [value])

  return (
    <Text as="div" align="center" variant={variant}>
      {numberToCurrency(value)}
    </Text>
  )
}

export default ReturnText
