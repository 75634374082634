import React from 'react'
import ReactDOM from 'react-dom'

import App from './App'

if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_MOCK === 'true') {
  // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
  const { worker } = require('./mocks/browser')
  worker.start()
}

ReactDOM.render(<App />, document.getElementById('root'))
