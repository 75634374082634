// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { useRouteMatch } from 'react-router-dom'

const usePortfolioInformationSubpage = () => {
  const routeMatch = useRouteMatch('/portefoeljer/:id/:subpage')

  let portfolioSubpage
  if (routeMatch && routeMatch.params.subpage) portfolioSubpage = routeMatch.params.subpage

  return portfolioSubpage
}

export default usePortfolioInformationSubpage
