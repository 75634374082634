import { changeCurrentPortfolio } from '@nord/ui/src/store/current/portfolioId'
import { updateUser } from '@nord/ui/src/store/current/user'
import { useDispatch } from 'react-redux'

const useUpdateUserAndPortfolio = () => {
  const dispatch = useDispatch()

  const update = ({
    sustainabilityPreferenceLevel,
    sustainabilityPreferenceGoalAnswer,
    user: { initialSustainabilityPreferenceLevel, sustainabilityPreferencesOrder },
  }: any) => {
    dispatch(
      updateUser({
        attributes: {
          initialSustainabilityPreferenceLevel,
          sustainabilityPreferencesOrder,
        },
      }),
    )

    dispatch(
      changeCurrentPortfolio({
        sustainabilityPreferenceLevel,
        sustainabilityPreferenceGoalAnswer,
      }),
    )
  }

  return update
}

export default useUpdateUserAndPortfolio
