import IconButtonLink from '@nord/ui/src/components/IconButtonLink'
import { PageSwitchContext } from '@nord/ui/src/components/PageSwitchTransition'
import Text from '@nord/ui/src/components/Text'
import useApi, { useApiRequest } from '@nord/ui/src/hooks/useApi'
import useQuery from '@nord/ui/src/hooks/useQuery'
import isEmpty from 'lodash/isEmpty'
import React, { useContext, useEffect, useState } from 'react'
import Alert from 'react-bootstrap/Alert'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import ArrowRight from 'react-feather/dist/icons/arrow-right'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { Link } from 'react-router-dom'

import PasswordForm from '../PasswordForm'

const ForgotPasswordLink = () => (
  <>
    <Link to="/brugere/adgangskode/glemt">Få tilsendt en ny email</Link>.
  </>
)

const alertTypes = {
  passwordCreated: {
    variant: 'success',
    message: 'Du kan nu logge ind med din nye adgangskode.',
    includeLink: false,
  },
  invalidToken: {
    variant: 'danger',
    message:
      'Linket til nulstilling af adgangskode er udløbet eller ugyldigt. Sørg for at brug linket i den seneste email du har modtaget.',
    includeLink: true,
  },
  noToken: {
    variant: 'danger',
    message:
      'Du kan kun få adgang til denne side, igennem et link du modtager på email. Hvis du allerede har modtaget denne email så sørg for at bruge den fulde URL, der er angivet.',
    includeLink: true,
  },
}

const ResetPasswordForm = () => {
  const [alert, setAlert] = useState({})
  const resetPasswordToken = useQuery('resetPasswordToken')
  const { active } = useContext(PageSwitchContext)

  const resetPassword = useApi('/users/password', {
    method: 'PATCH',
    errorHandling: {
      ignore: {
        client: true,
      },
    },
  })

  const { request: callResetPassword, success: responseIsSuccess } = useApiRequest(resetPassword)

  const handleSubmit = async (values: any, { resetForm, setErrors }: any) => {
    const {
      success,
      data: { errors },
    } = await callResetPassword({
      user: {
        resetPasswordToken,
        ...values,
      },
    })

    if (success) {
      resetForm({
        values: {
          password: '',
          passwordConfirmation: '',
        },
      })
      setAlert(alertTypes.passwordCreated)
    } else {
      setAlert(alertTypes.invalidToken)
    }

    if (errors) setErrors(errors)
  }

  useEffect(() => {
    if (resetPasswordToken || active) return

    setAlert(alertTypes.noToken)
  }, [active, resetPasswordToken])

  return (
    <>
      <Text as="h2" align="center" size="xl" className="mb-5">
        Nulstil din adgangskode
      </Text>
      {!isEmpty(alert) && (
        // @ts-expect-error TS(2339) FIXME: Property 'variant' does not exist on type '{}'.
        <Alert variant={alert.variant} className="text-center">
          {/* @ts-expect-error TS(2339) FIXME: Property 'message' does not exist on type '{}'. */}
          {alert.message}
          {/* @ts-expect-error TS(2339) FIXME: Property 'includeLink' does not exist on type '{}'... Remove this comment to see the full error message */}
          {alert.includeLink && (
            <>
              <br />
              <ForgotPasswordLink />
            </>
          )}
        </Alert>
      )}
      {responseIsSuccess ? (
        <div className="d-flex justify-content-center">
          <IconButtonLink icon={ArrowRight} to="/brugere/logind">
            Gå til log ind
          </IconButtonLink>
        </div>
      ) : (
        <PasswordForm
          buttonText="Nulstil din adgangskode"
          disabledInputs={!resetPasswordToken}
          // @ts-expect-error TS(2322) FIXME: Type '{ buttonText: string; disabledInputs: boolea... Remove this comment to see the full error message
          onSubmit={handleSubmit}
        />
      )}
    </>
  )
}

export default ResetPasswordForm
