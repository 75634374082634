import { getPortfolioChartLive } from '@nord/api'
import { useRequest } from '@nord/ui/src'
import Loader from '@nord/ui/src/components/Loader'
import Text from '@nord/ui/src/components/Text'
import useObjectSelector from '@nord/ui/src/hooks/useObjectSelector'
import { selectCurrentPortfolio } from '@nord/ui/src/store/current/portfolioId'
import { numberToCurrency } from '@nord/ui/src/utilities/numberFormatter'
import React from 'react'

const AccountValue = () => {
  // @ts-expect-error TS(2339) FIXME: Property 'totalValue' does not exist on type 'unkn... Remove this comment to see the full error message
  const { id } = useObjectSelector(selectCurrentPortfolio)

  const { data: liveData } = useRequest(
    getPortfolioChartLive,
    [{ portfolioId: id, chartType: 'values' }],
    {
      refetchInterval: 60_000,
    },
  )

  return (
    <div className="pt-2">
      <Text as="h6" align="center">
        Værdi
      </Text>
      <Text as="h1" align="center" variant="white" className="mb-0">
        {liveData ? numberToCurrency(liveData.value) : <Loader />}
      </Text>
    </div>
  )
}

export default AccountValue
