import BaffleButton from '@nord/ui/src/components/BaffleButton'
import DescriptionList from '@nord/ui/src/components/DescriptionList'
import Loader from '@nord/ui/src/components/Loader'
import useApi, { useApiRequest } from '@nord/ui/src/hooks/useApi'
import useObjectSelector from '@nord/ui/src/hooks/useObjectSelector'
import { selectPortfolioById } from '@nord/ui/src/store/current/portfolios'
import { numberToCurrency } from '@nord/ui/src/utilities/numberFormatter'
import isEmpty from 'lodash/isEmpty'
import React from 'react'
import Alert from 'react-bootstrap/Alert'
import Card from 'react-bootstrap/Card'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { Link, useParams } from 'react-router-dom'

const unsuccessfulStatuses = ['failed', 'cancelled']

const alertVariants = {
  failed: 'danger',
  cancelled: 'danger',
  succeeded: 'success',
  pending: 'warning',
}

const ResultPage = () => {
  const { paymentId } = useParams()

  const fetchPayment = useApi(`/payments/${paymentId}`, {
    method: 'GET',
    withCredentials: true,
  })

  const { data: paymentData, called: paymentDataIsCalled } = useApiRequest(fetchPayment, {
    autoCall: !!paymentId,
  })

  const { status, message, amount, portfolioId } = paymentData || {}
  // @ts-expect-error TS(2339) FIXME: Property 'title' does not exist on type 'unknown'.
  const { title, registrationNumber, saxoAccountNumber } = useObjectSelector(
    selectPortfolioById(portfolioId),
  )
  const portfolioInformation = `${title}: ${registrationNumber} - ${saxoAccountNumber}`

  return (
    <>
      {!paymentDataIsCalled && <Loader className="font-size-lg" />}
      {!isEmpty(paymentData) && (
        // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        <Alert variant={alertVariants[status]} className="mt-3">
          <div className="d-flex flex-row justify-content-center">{message}</div>
        </Alert>
      )}
      {status && (status === 'succeeded' || status === 'pending') && (
        <Card className="my-3">
          <Card.Body className="mx-lg-5">
            <DescriptionList className="text-center text-lg-left">
              {/* @ts-expect-error TS(2322) FIXME: Type '{ label: string; value: string; xs: number; ... Remove this comment to see the full error message */}
              <DescriptionList.Item label="Til konto" value={portfolioInformation} xs={12} lg={6} />
              {/* @ts-expect-error TS(2322) FIXME: Type '{ label: string; value: string; xs: number; ... Remove this comment to see the full error message */}
              <DescriptionList.Item label="Beløb" value={numberToCurrency(amount)} xs={12} lg={6} />
            </DescriptionList>
          </Card.Body>
        </Card>
      )}
      {status && unsuccessfulStatuses.includes(status) && (
        <BaffleButton as={Link} to="/konto/kontant_indbetaling/opret">
          Prøv igen
        </BaffleButton>
      )}
    </>
  )
}

export default ResultPage
