import Sustainability from '@nord/ui/src/components/Sustainability'
import { selectCurrentPortfolioId } from '@nord/ui/src/store/current/portfolioId'
import React from 'react'
import { useSelector } from 'react-redux'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { useHistory } from 'react-router-dom'

import usePageLeaveWarning from '../../../../../hooks/usePageLeaveWarning'
import useFinishSustainabilityUpdate from '../hooks/useFinishSustainabilityUpdate'
import useSustainabilityRedirect from '../hooks/useSustainabilityRedirect'
import useUpdateSustainabilityPreference from '../hooks/useUpdateSustainabilityPreferences'
import { useSustainabilityContext } from '../Provider'

const WarningPage = () => {
  // @ts-expect-error TS(2339) FIXME: Property 'answerData' does not exist on type 'unkn... Remove this comment to see the full error message
  const { answerData, questionsVersion, preferencesOrder } = useSustainabilityContext()
  const updateSustainabilityPreferences = useUpdateSustainabilityPreference()
  const history = useHistory()
  const portfolioId = useSelector(selectCurrentPortfolioId)

  useSustainabilityRedirect()
  usePageLeaveWarning()

  const finishSustainabilityUpdate = useFinishSustainabilityUpdate()

  const handleSubmit = async ({ acceptLowerSustainabilityPreference }: any) => {
    const { data, success } = await updateSustainabilityPreferences({
      portfolio: {
        investmentProfileAttributes: {
          version: questionsVersion,
          answers: [answerData],
        },
        sustainabilityPreferencesOrder: preferencesOrder,
        acceptLowerSustainabilityPreference,
      },
    })

    if (!success) {
      history.push(`/portefoeljer/${portfolioId}/anbefaling?success=false`)

      return
    }

    finishSustainabilityUpdate(data)
  }

  return (
    <div className="my-3">
      <Sustainability.Warning
        // @ts-expect-error TS(2322) FIXME: Type '{ onSubmit: ({ acceptLowerSustainabilityPref... Remove this comment to see the full error message
        onSubmit={handleSubmit}
        submitButtonProps={{ filled: true }}
        withSubmitButton
      />
    </div>
  )
}

export default WarningPage
