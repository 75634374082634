import NavMenu from '@nord/ui/src/components/NavMenu'
import React from 'react'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { NavLink } from 'react-router-dom'

const MyAccountMenuItem = () => (
  // @ts-expect-error TS(2322) FIXME: Type '{ children: Element; className: string; }' i... Remove this comment to see the full error message
  <NavMenu.Item className="d-none d-lg-block">
    {/* @ts-expect-error TS(2322) FIXME: Type '{ children: string; as: any; to: string; act... Remove this comment to see the full error message */}
    <NavMenu.Link activeClassName="text-deep-dark" as={NavLink} to="/konto" animated>
      Min konto
    </NavMenu.Link>
  </NavMenu.Item>
)

export default MyAccountMenuItem
