import Form from '@nord/ui/src/components/Form'
import Icon from '@nord/ui/src/components/Icon'
import PasswordInput from '@nord/ui/src/components/PasswordInput'
import Text from '@nord/ui/src/components/Text'
import useApi from '@nord/ui/src/hooks/useApi'
import React from 'react'
import Button from 'react-bootstrap/Button'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import ArrowLeft from 'react-feather/dist/icons/arrow-left'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { useHistory } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'

import CenteredTextSection from '../../../../components/CenteredTextSection'
import usePageLeaveWarning from '../../../../hooks/usePageLeaveWarning'

import validationSchema from './validationSchema'

const labelDescriptions = {
  currentPassword: 'Aktuel adgangskode',
  password: 'Ny adgangskode',
  passwordConfirmation: 'Bekræft ny adgangskode',
}

const ChangePassword = () => {
  const history = useHistory()
  const { addToast } = useToasts()

  usePageLeaveWarning()

  const handleGoBack = () => history.push('/konto')

  const updateUser = useApi(`/users`, {
    method: 'PATCH',
    withCredentials: true,
  })

  const handleSubmit = async (values: any, formik: any) => {
    const {
      data: { errors },
    } = await updateUser({
      user: values,
    })

    if (errors) {
      formik.setErrors(errors)

      return
    }

    addToast('Din adgangskode er nu redigeret.', {
      appereance: 'info',
      autoDismiss: true,
    })
    handleGoBack()
  }

  return (
    <>
      <div className="d-flex align-items-center">
        <Button variant="link-dark" className="px-0 my-auto mr-auto">
          {/* @ts-expect-error TS(2322) FIXME: Type '{ icon: any; onClick: () => any; size: strin... Remove this comment to see the full error message */}
          <Icon icon={ArrowLeft} onClick={handleGoBack} size="20" />
        </Button>
        <Text as="h4" align="center" className="my-3 mr-auto">
          Skift adgangskode
        </Text>
      </div>
      <CenteredTextSection>
        <Form
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          labelDescriptions={labelDescriptions}
          // @ts-expect-error TS(2322) FIXME: Type '{ children: Element[]; validationSchema: Obj... Remove this comment to see the full error message
          className="text-left"
          validateOnMount
        >
          <Form.FieldGroup name="currentPassword">
            {/* @ts-expect-error TS(2322) FIXME: Type '(props: Props & RefAttributes<any>) => React... Remove this comment to see the full error message */}
            <Form.Field inputComponent={PasswordInput} autoFocus />
          </Form.FieldGroup>
          <Form.FieldGroup name="password">
            {/* @ts-expect-error TS(2322) FIXME: Type '(props: Props & RefAttributes<any>) => React... Remove this comment to see the full error message */}
            <Form.Field inputComponent={PasswordInput} />
          </Form.FieldGroup>
          <Form.FieldGroup name="passwordConfirmation">
            {/* @ts-expect-error TS(2322) FIXME: Type '(props: Props & RefAttributes<any>) => React... Remove this comment to see the full error message */}
            <Form.Field inputComponent={PasswordInput} />
          </Form.FieldGroup>
          <div className="d-flex justify-content-center my-4">
            {/* @ts-expect-error TS(2322) FIXME: Type '{ children: string; variant: string; disable... Remove this comment to see the full error message */}
            <Form.SubmitButton variant="primary" disableOnInvalid>
              Skift adgangskode
            </Form.SubmitButton>
          </div>
        </Form>
      </CenteredTextSection>
    </>
  )
}

export default ChangePassword
