import styles from '../../../components/Transition/Slide.module.scss'

const duration = parseInt(styles.timeout, 10)
const translate = parseInt(styles.translateY, 10)

const slideUp = {
  translateY: [-translate, 0],
  opacity: [0, 1],
  duration,
  easing: styles.easing,
}

export default slideUp
